var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias2(__default(require("../helpers/log.js")).call(alias1,"~~~~~~~ rendering party-orig",depth0,{"name":"log","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":43}}}))
    + "\n\n<section\n  id=\"_selectedParty\"\n  class=\""
    + alias2(__default(require("./section_classes.js")).call(alias1,{"name":"section_classes","hash":{},"data":data,"loc":{"start":{"line":5,"column":9},"end":{"line":5,"column":28}}}))
    + "\"\n  data-attendee-id=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"a_id") : stack1), depth0))
    + "\"\n  data-rsvp-id=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"r_id") : stack1), depth0))
    + "\"\n  data-current-party=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"p_id") : stack1), depth0))
    + "\"\n  data-selected-party=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"p_id") : stack1), depth0))
    + "\"\n  rendered=\"true\">\n\n  <div id=\"_filterLock\" data-lock=\"always\" class=\"d-none\"></div>\n  <div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"publicContainer")), depth0))
    + "\">\n\n    <!--- Public Menu -->\n"
    + ((stack1 = container.invokePartial(require("./public-menu.handlebars"),depth0,{"name":"public-menu","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    <div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"publicContent")), depth0))
    + "\">\n\n      <!-- Public Section Info -->\n"
    + ((stack1 = container.invokePartial(require("./public-section-info.handlebars"),depth0,{"name":"public-section-info","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n      <!--- Grid -->\n"
    + ((stack1 = container.invokePartial(require("./public-section-grid.handlebars"),depth0,{"name":"public-section-grid","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n      <!-- Grid Detail -->\n"
    + ((stack1 = container.invokePartial(require("./public-section-grid-detail.handlebars"),depth0,{"name":"public-section-grid-detail","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n      <!-- RSVP -->\n"
    + ((stack1 = container.invokePartial(require("./public-section-rsvp.handlebars"),depth0,{"name":"public-section-rsvp","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n      <!-- Profile -->\n"
    + ((stack1 = container.invokePartial(require("./public-section-profile.handlebars"),depth0,{"name":"public-section-profile","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    </div> <!-- .public-content -->\n\n    <!-- Footer -->\n"
    + ((stack1 = container.invokePartial(require("./public-footer.handlebars"),depth0,{"name":"public-footer","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n  </div> <!-- public container -->\n</section>\n"
    + ((stack1 = container.invokePartial(require("../partials/scroll-to-top.handlebars"),depth0,{"name":"scroll-to-top","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});