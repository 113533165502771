var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"_fg-edit-post "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"editPost")), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"four")), depth0))
    + "\"\n       data-post-id=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"response"))) && lookupProperty(stack1,"a_id")), depth0))
    + "\">\n\n"
    + ((stack1 = container.invokePartial(require("./crud-single-input.handlebars"),depth0,{"name":"crud-single-input","hash":{"placeholder":(depth0 != null ? lookupProperty(depth0,"first_name") : depth0),"action":"user_edit_attendee_meta","meta_key":"wpcf-first-name","label":"Name"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,__default(require("../helpers/utils.js")).call(alias3,"admin_logged_in",{"name":"utils","hash":{},"data":data,"loc":{"start":{"line":13,"column":10},"end":{"line":13,"column":35}}}),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":16,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./crud-single-input.handlebars"),depth0,{"name":"crud-single-input","hash":{"placeholder":(depth0 != null ? lookupProperty(depth0,"location") : depth0),"action":"user_edit_attendee_meta","meta_key":"wpcf-location","label":"Location"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./crud-single-input.handlebars"),depth0,{"name":"crud-single-input","hash":{"classes":"_fg-user-age","placeholder":(depth0 != null ? lookupProperty(depth0,"age") : depth0),"action":"user_edit_attendee_meta","meta_key":"wpcf-age","label":"Age"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./crud-single-input.handlebars"),depth0,{"name":"crud-single-input","hash":{"private":"1","contact_id":(depth0 != null ? lookupProperty(depth0,"contact_id") : depth0),"classes":"_fg-user-sms","placeholder":__default(require("../helpers/padSMS.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"sms") : depth0),{"name":"padSMS","hash":{},"data":data,"loc":{"start":{"line":22,"column":93},"end":{"line":22,"column":105}}}),"action":"add_update_sms","meta_key":"wpcf-sms","label":"SMS"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./crud-radio-group.handlebars"),depth0,{"name":"crud-radio-group","hash":{"radio_data":(depth0 != null ? lookupProperty(depth0,"allow_sms") : depth0),"action":"user_edit_attendee_meta","label":"SMS Alerts"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./crud-checkbox-group.handlebars"),depth0,{"name":"crud-checkbox-group","hash":{"checkbox_data":(depth0 != null ? lookupProperty(depth0,"position") : depth0),"action":"user_edit_attendee_meta","label":"Position"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./crud-radio-group.handlebars"),depth0,{"name":"crud-radio-group","hash":{"radio_data":(depth0 != null ? lookupProperty(depth0,"allow_face") : depth0),"action":"user_edit_attendee_meta","label":"Allow Face?"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./tag-cloud.handlebars"),depth0,{"name":"tag-cloud","hash":{"label":(depth0 != null ? lookupProperty(depth0,"tag_labels") : depth0),"tag_data":(depth0 != null ? lookupProperty(depth0,"tag_data") : depth0)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,__default(require("../helpers/utils.js")).call(alias3,"admin_logged_in",{"name":"utils","hash":{},"data":data,"loc":{"start":{"line":32,"column":10},"end":{"line":32,"column":35}}}),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":4},"end":{"line":38,"column":11}}})) != null ? stack1 : "")
    + "\n  </div> \n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,__default(require("../helpers/utils.js")).call(alias3,"admin_logged_in",{"name":"utils","hash":{},"data":data,"loc":{"start":{"line":42,"column":8},"end":{"line":42,"column":33}}}),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":2},"end":{"line":44,"column":9}}})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./crud-single-input.handlebars"),depth0,{"name":"crud-single-input","hash":{"placeholder":(depth0 != null ? lookupProperty(depth0,"first_name_private") : depth0),"action":"user_edit_attendee_meta","meta_key":"wpcf-first-name-private","label":"First Name Private"},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./crud-single-input.handlebars"),depth0,{"name":"crud-single-input","hash":{"placeholder":(depth0 != null ? lookupProperty(depth0,"last_name") : depth0),"action":"user_edit_attendee_meta","meta_key":"wpcf-last-name","label":"Last"},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./crud-single-input.handlebars"),depth0,{"name":"crud-single-input","hash":{"term":(depth0 != null ? lookupProperty(depth0,"note") : depth0),"classes":"_fg-user-note _fg_searchable","placeholder":(depth0 != null ? lookupProperty(depth0,"note") : depth0),"action":"user_edit_attendee_meta","meta_key":"wpcf-attendee-note","label":"Attendee Note"},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      <div class=\"_fg-editable "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"editable")), depth0))
    + "\">\n        <label class=\"prompt-label\">Rating</label>\n"
    + ((stack1 = container.invokePartial(require("./ratings.handlebars"),depth0,{"name":"ratings","hash":{"hover":false,"a_id":((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"response"))) && lookupProperty(stack1,"a_id")),"rating":(depth0 != null ? lookupProperty(depth0,"rating") : depth0)},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./crud-user-profile-admin-extras.handlebars"),depth0,{"name":"crud-user-profile-admin-extras","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + container.escapeExpression(__default(require("../helpers/log.js")).call(alias1,"~~~~~~~ rendering crud-user-profile",depth0,{"name":"log","hash":{},"data":data,"loc":{"start":{"line":5,"column":0},"end":{"line":5,"column":50}}}))
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"user_profile") : stack1),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":0},"end":{"line":46,"column":9}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});