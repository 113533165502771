var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div class=\"_load-more "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"loadMore")), depth0))
    + "\">\n  <button class=\"btn btn-outline-dark handle-click\"\n          data-module=\"handle_load_more\"\n          data-last-seen=\""
    + alias2(__default(require("../helpers/last-seen.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"data") : depth0),(depth0 != null ? lookupProperty(depth0,"key") : depth0),{"name":"last-seen","hash":{},"data":data,"loc":{"start":{"line":9,"column":26},"end":{"line":9,"column":48}}}))
    + "\"\n          data-target=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"target") : depth0), depth0))
    + "\">Load More</button>\n</div>";
},"useData":true});