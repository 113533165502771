var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        Forbidden Content\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        "
    + container.escapeExpression(__default(require("../helpers/defaultVal.js")).call(alias1,__default(require("../helpers/utils.js")).call(alias1,"getQueryString","message",{"name":"utils","hash":{},"data":data,"loc":{"start":{"line":11,"column":21},"end":{"line":11,"column":55}}}),"Hold Up!",{"name":"defaultVal","hash":{},"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":11,"column":68}}}))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "This area of the web site is off-limits\n";
},"7":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../helpers/utils.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"getQueryString","error",{"name":"utils","hash":{},"data":data,"loc":{"start":{"line":19,"column":10},"end":{"line":19,"column":44}}}))
    + "\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("../partials/login.handlebars"),depth0,{"name":"login","hash":{"fragment":"#grid","login_redirect":__default(require("../helpers/utils.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"getQueryString","login_redirect",{"name":"utils","hash":{},"data":data,"loc":{"start":{"line":24,"column":31},"end":{"line":24,"column":72}}})},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias2(__default(require("../helpers/setVar.js")).call(alias1,"pathname",__default(require("../helpers/get.js")).call(alias1,"pathname",__default(require("../helpers/get.js")).call(alias1,"location",__default(require("../helpers/window.js")).call(alias1,{"name":"window","hash":{},"data":data,"loc":{"start":{"line":1,"column":52},"end":{"line":1,"column":60}}}),{"name":"get","hash":{},"data":data,"loc":{"start":{"line":1,"column":36},"end":{"line":1,"column":61}}}),{"name":"get","hash":{},"data":data,"loc":{"start":{"line":1,"column":20},"end":{"line":1,"column":62}}}),{"name":"setVar","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":64}}}))
    + "\n"
    + alias2(__default(require("../helpers/setVar.js")).call(alias1,"isForbiddenImage",__default(require("../helpers/regex.js")).call(alias1,"\\.(webp|jpe?g|gif|png|txt|html)$",((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"pathname")),{"name":"regex","hash":{},"data":data,"loc":{"start":{"line":2,"column":28},"end":{"line":2,"column":85}}}),{"name":"setVar","hash":{},"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":2,"column":87}}}))
    + "\n\n<section class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"center")), depth0))
    + " "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"spreadEagle")), depth0))
    + "\">\n\n  <div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"userErrorScreen")), depth0))
    + "\">\n    <div class=\"pt-5 display-4 title _fg_user_error_header\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"isForbiddenImage")),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":8,"column":6},"end":{"line":12,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n    <div>\n      <div class=\"text-danger "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"errorMessage")), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"isForbiddenImage")),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":20,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n    </div>\n"
    + ((stack1 = __default(require("../helpers/ifCond.js")).call(alias1,__default(require("../helpers/utils.js")).call(alias1,"getQueryString","follow_link",{"name":"utils","hash":{},"data":data,"loc":{"start":{"line":23,"column":14},"end":{"line":23,"column":52}}}),"!=","",{"name":"ifCond","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":4},"end":{"line":25,"column":15}}})) != null ? stack1 : "")
    + "  </div>\n\n</section>";
},"usePartial":true,"useData":true});