export default function() {
  var section_classes = [
    this.styles.fgPublic,
    this.styles.legsSpread
  ];
  var p_id = this.response.p_id;
  if (!this.response.user_profile.password_exists) {
    section_classes.push('_set-your-password');
  }

  var partyIsFirstClass = this.response.active_parties[p_id].is_party_first_class,
      userIsFirstClass = this.response.active_parties[p_id].is_first_class_user,
      emergencyAlertExists = this.response.emergency_alert || false;

  // Emergency Alert takes precedence
  if ( emergencyAlertExists ) {
    section_classes.push(this.styles.emergencyAlert);
    section_classes.push('_emergency-alert');
  } else if ( partyIsFirstClass && userIsFirstClass ) {
    section_classes.push(this.styles.firstClass);
    section_classes.push('_first-class');
  }

  return section_classes.join(' ');
}
