var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <section class=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"center") : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"hotelBed") : depth0), depth0))
    + " px-3\">\n    <div class=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"deadCenterEvenly") : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"nudgeUp") : depth0), depth0))
    + "\">\n      <div class=\"animated zoomInDown display-3\">WTF?</div>\n      <div style=\"line-height: 2\" class=\"h4 text-center\">You might be lost. This page doesn't exist.</div>\n      <div><a href=\""
    + alias2(__default(require("../helpers/home_url.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"/",{"name":"home_url","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":36}}}))
    + "\"\n           class=\"btn btn-outline-primary handle-click\"\n           data-module=\"handle_navigate\"\n           data-template=\"home\">Start from the beginning</a></div>\n    </div>\n  </section>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"styles") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":12,"column":9}}})) != null ? stack1 : "");
},"useData":true});