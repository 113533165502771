var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias1(__default(require("../helpers/log.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"~~~~~~~ rendering crud-user-rsvp",depth0,{"name":"log","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":47}}}))
    + "\n\n<div class=\""
    + alias1(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"alertPanel")), depth0))
    + "\">\n\n	<div class=\""
    + alias1(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fgAlertSm_danger")), depth0))
    + " _fg-waitlist-alert d-none\">\n		<i class=\"fas fa-exclamation-circle _icon "
    + alias1(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"icon")), depth0))
    + "\"></i>\n		<span class=\"_alert-text "
    + alias1(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"alertText")), depth0))
    + "\">Waitlist in effect for new replies</span>\n	</div>\n\n	<div class=\""
    + alias1(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fgAlertSm_info")), depth0))
    + " _fg-pic-alert-pending-approval d-none\">\n		<i class=\"fas fa-info-circle _icon "
    + alias1(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"icon")), depth0))
    + "\"></i>\n		<span class=\"_alert-text "
    + alias1(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"alertText")), depth0))
    + "\">Pics are pending approval. Once approved you will have full capabilities to see the guys who RSVP.</span>\n	</div>\n\n	<div\n		class=\"collapse "
    + alias1(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"collapseWaitlistMoreInfo")), depth0))
    + "\"\n		id=\"collapseWaitlistMoreInfo\"\n	>\n		<div class=\""
    + alias1(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"moreInfoBox")), depth0))
    + " "
    + alias1(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"blurredBackdrop")), depth0))
    + " "
    + alias1(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"flexGapColumn3")), depth0))
    + "\">\n			<h2>\n				What's this <span class=\"text-accent-color\">waitlist</span> shit?\n			</h2>\n			<h4 class=\"text-accent-color\">RSVP too late?</h4>\n			<p>This basically means the attendance is over the limit. Based on past events, I have an idea how many guys can fit\n				into the space we have. When there's too many, it's not pleasant and makes it difficult to move around.</p>\n			<p>What this means for you: As guys who RSVP'd earlier change their reply to \"No\", I go down the list and fill their\n				spot by reaching out to the guys on the list individually. Please keep an eye on your email or sign up for text\n				alerts if you'd like to be included. Otherwise, I go on to the next guy. It sucks, I know, it's not easy for me\n				either.</p>\n			<h4 class=\"text-accent-color\">Never received the invite?</h4>\n			<p>This is probably correct. I stop sending invitations when the party is full. Long story short, notices are sent out very slowly to\n				protect our sender reputation, which is a score that is assigned by everyone's email provider. It’s a crucial component\n				of our email deliverability and also applies to SMS. Because parties fill up faster than all invites can be sent out, I see no reason to keep sending them.\n				You should check the website more often as we are leading up to the next party. \n			</p>\n			<div>\n				<button\n					class=\"btn btn-default btn-sm\"\n					data-toggle=\"collapse\"\n					data-target=\"#collapseWaitlistMoreInfo\"\n					aria-expanded=\"false\"\n					aria-controls=\"collapseWaitlistMoreInfo\"\n				>Dismiss\n				</button>\n			</div>\n		</div>\n	</div>\n\n</div>\n\n"
    + ((stack1 = container.invokePartial(require("./crud-user-rsvp-data.handlebars"),depth0,{"name":"crud-user-rsvp-data","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});