var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  "
    + alias2(__default(require("../helpers/setVar.js")).call(alias1,"starStyle",((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"starRatingHover")),{"name":"setVar","hash":{},"data":data,"loc":{"start":{"line":8,"column":2},"end":{"line":8,"column":53}}}))
    + "\n  "
    + alias2(__default(require("../helpers/setVar.js")).call(alias1,"lazyId",__default(require("../helpers/concat.js")).call(alias1,"starRating-hover-",(depth0 != null ? lookupProperty(depth0,"a_id") : depth0),{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":9,"column":53}}}),{"name":"setVar","hash":{},"data":data,"loc":{"start":{"line":9,"column":2},"end":{"line":9,"column":56}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  "
    + alias2(__default(require("../helpers/setVar.js")).call(alias1,"starStyle",((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"starRating")),{"name":"setVar","hash":{},"data":data,"loc":{"start":{"line":11,"column":2},"end":{"line":11,"column":48}}}))
    + "\n  "
    + alias2(__default(require("../helpers/setVar.js")).call(alias1,"lazyId",__default(require("../helpers/concat.js")).call(alias1,"starRating-nohover-",(depth0 != null ? lookupProperty(depth0,"a_id") : depth0),{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":12,"column":55}}}),{"name":"setVar","hash":{},"data":data,"loc":{"start":{"line":12,"column":2},"end":{"line":12,"column":58}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hover") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":7,"column":0},"end":{"line":13,"column":7}}})) != null ? stack1 : "")
    + "\n<div class=\"_star-rating _fg-searchable "
    + alias2(alias1(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"starStyle")), depth0))
    + "\"\n     data-rating=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"rating") : depth0), depth0))
    + "\"\n     data-attendee-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"a_id") : depth0), depth0))
    + "\"\n     data-target=\"#"
    + alias2(alias1(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lazyId")), depth0))
    + "\"\n     data-term=\"rating:"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"rating") : depth0), depth0))
    + "\">\n  <span id=\""
    + alias2(alias1(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lazyId")), depth0))
    + "\">\n"
    + ((stack1 = container.invokePartial(require("./ratings-lazy.handlebars"),depth0,{"name":"ratings-lazy","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </span>\n</div>";
},"usePartial":true,"useData":true});