var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section>\n	<div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"center")), depth0))
    + " position-relative "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"spreadEagle")), depth0))
    + "\">\n		<div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"homeContainer")), depth0))
    + " py-5\">\n			<div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"homeElement")), depth0))
    + "\">\n				<div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"homeMasthead")), depth0))
    + "\"></div>\n			</div>\n			<div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"homeElement")), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"tagline")), depth0))
    + " px-3\">\n				<h4 class=\"text-accent-color mb-5\" style=\"letter-spacing: .01em;\">High-quality, uninhibited group sex for men in Boston</h4>\n				<div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"sublead")), depth0))
    + "\">\n					<div class=\"text-center\" style=\"line-height: 1.8\">\n            Reliable and hassle-free events for men 18 to 40s who enjoy adult-themed social gatherings with other men. Requesting to join is the best way to receive additional information and invitations to events.</div>\n				</div>\n			</div>\n\n			<div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"homeElement")), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"homeActions")), depth0))
    + "\">\n				<div>\n					<h4>New to the group?</h4>\n					<a class=\"btn btn-success fg-get-invited-btn "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"buttonAction")), depth0))
    + "\" href=\"#collapseInvite\">GET INVITED</a>\n				</div>\n        <div>\n					<h4>Already on the list?</h4>\n					<a href=\""
    + ((stack1 = __default(require("../helpers/home_url.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"/getin/",{"name":"home_url","hash":{},"data":data,"loc":{"start":{"line":22,"column":14},"end":{"line":22,"column":38}}})) != null ? stack1 : "")
    + "\" class=\"btn btn-legiblue "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"buttonAction")), depth0))
    + " handle-click\" data-module=\"handle_navigate\" data-template=\"reset\">GET IN</a>\n				</div>\n      </div>\n\n			<div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"homeElement")), depth0))
    + "\">\n				<a target=\"_blank\" href=\"https://twitter.com/wantgroupm4m\" class=\"p-3 btn btn-link color-twitter\" rel=\"noopener noreferrer\"><i class=\"fab fa-twitter fa-2x\"></i></a>\n				<a target=\"_blank\" href=\"https://www.snapchat.com/add/wantgroup?share_id=Qjg2RjNC&locale=en_US\" class=\"p-3 btn btn-link color-snapchat\" rel=\"noopener noreferrer\"><i class=\"fab fa-snapchat-ghost fa-2x\"></i></a>\n			</div>\n\n			<div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"comply")), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"homeElement")), depth0))
    + "\">\n				<span class=\"text-center\">If you are under the age of 18, do not continue.</span>\n			</div>\n\n		</div> <!-- home-flex-container -->\n\n	</div> <!-- #fg-delegate -->\n\n</section>\n\n<section style=\"padding-bottom: 50vh\">\n	<div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"requestInvitation")), depth0))
    + "\">\n		<h3 id=\"collapseInvite\" class=\"h1 pt-2 "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"inviteSection")), depth0))
    + "\">Request Invitation</h3>\n		<div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"publicForm")), depth0))
    + " my-5 text-info\">Parties are invitation only. Although a referral is not required at this time, it weighs heavily. Use the form below to provide that information and to\n			elaborate on your desire to participate.</div>\n		<div class=\"accordion "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"accordionDigMoreInfo")), depth0))
    + "\" id=\"accordionDig\">\n			<div class=\"mb-4 "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"flexGap3")), depth0))
    + "\" style=\"align-items: flex-start\">\n				<button class=\"btn btn-link "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"plusMinus")), depth0))
    + " collapsed\" data-toggle=\"collapse\" data-target=\"#collapseDigWhatHappens\" aria-expanded=\"false\" aria-controls=\"collapseDigWhatHappens\">What happens after you request?</button>\n				<button class=\"btn btn-link "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"plusMinus")), depth0))
    + " collapsed\" data-toggle=\"collapse\" data-target=\"#collapseDigConfidentiality\" aria-expanded=\"false\" aria-controls=\"collapseDigConfidentiality\">Confidentiality</button>\n			</div>\n			<div class=\"collapse mb-4 "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"blurred_info")), depth0))
    + "\" id=\"collapseDigWhatHappens\" data-parent=\"#accordionDig\">\n			  <div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"publicForm")), depth0))
    + "\">\n					<div class=\"py-3 px-4\">You'll receive an email asking to simply reply. This is to verify you are not a bot. After that, you'll be sent the welcome note with all the info and FAQ's including what is needed to get in on the action.</div>\n			  </div>\n			</div>\n			<div class=\"collapse mb-4 "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"blurred_info")), depth0))
    + "\" id=\"collapseDigConfidentiality\" data-parent=\"#accordionDig\">\n			  <div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"publicForm")), depth0))
    + "\">\n					<div class=\"py-3 px-4\">The information you provide here is will never, ever be shared. It's only used to distinguish you from other guys and to let you know when the next party will be. Guys who identify as gay, bi and DL attend the parties and all of us have circumstances which attending orgies doesn't play well. We're here to offer a safe, private environment for everyone.</div>\n			  </div>\n			</div>\n		</div>\n"
    + ((stack1 = container.invokePartial(require("./invitation-request.handlebars"),depth0,{"name":"invitation-request","data":data,"indent":"   ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n</section>\n"
    + ((stack1 = container.invokePartial(require("../partials/scroll-to-top.handlebars"),depth0,{"name":"scroll-to-top","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});