var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"h3\">Invalid RSVP: <span style=\"color: #000;\">&ldquo;"
    + alias1(((helper = (helper = lookupProperty(helpers,"reply_human") || (depth0 != null ? lookupProperty(depth0,"reply_human") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"reply_human","hash":{},"data":data,"loc":{"start":{"line":1,"column":64},"end":{"line":1,"column":79}}}) : helper)))
    + "&rdquo;</span></div>\n<div class=\"h5\" style=\"line-height: 1.6\">You did not reply <span class=\""
    + alias1(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"underline")), depth0))
    + "\">Yes</span> or <span class=\""
    + alias1(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"underline")), depth0))
    + "\">Maybe</span> to this party. You will be allowed in, but Parker has been notified. If you were on the wait list, the system may not have been updated.</div>\n";
},"useData":true});