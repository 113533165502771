var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"verified")), depth0))
    + " verified";
},"3":function(container,depth0,helpers,partials,data) {
    return "Yes";
},"5":function(container,depth0,helpers,partials,data) {
    return "Not Verified";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fgAlertSm_danger")), depth0))
    + " _unset-alert\">\n                  <i class=\"fas fa-info-circle _icon "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"icon")), depth0))
    + "\"></i>\n                  <span>Your password is currently unset.</span>\n                </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "Change";
},"11":function(container,depth0,helpers,partials,data) {
    return "Set";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias2(__default(require("../helpers/log.js")).call(alias1,"~~~~~~~ rendering public-section-profile",depth0,{"name":"log","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":55}}}))
    + "\n\n<div class=\"_fg-public-section-profile d-none animated fadeIn\" data-menu=\"_fg-click-public-profile\">\n  <div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"yourProfileNav")), depth0))
    + " mb-5\">\n    <div><a href=\"#profile-personal-info\" class=\"btn btn-default btn-pill outline\">Personal Info</a></div>\n    <div><a href=\"#password-change\" class=\"btn btn-default btn-pill outline\">Password</a></div>\n    <div><a href=\"#_your-images\" class=\"btn btn-default btn-pill outline\">Your Images</a></div>\n  </div>\n  <div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fullWidthSm")), depth0))
    + "\">\n    <div>\n      <span id=\"profile-personal-info\"></span>\n\n      <!-- Crud user profile -->\n"
    + ((stack1 = container.invokePartial(require("../partials/crud-user-profile.handlebars"),depth0,{"name":"crud-user-profile","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n      <hr id=\"password-change\" class=\"my-3\" />\n      <div class=\"_fg-change-password "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"profileChangePassword")), depth0))
    + " py-4\">\n        <div>\n          <label class=\"prompt-label mb-2\">VAX Verified COVID:</label>\n          <div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"vaxStatus")), depth0))
    + " _vax-status "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"user_profile") : stack1)) != null ? lookupProperty(stack1,"is_vaccinated") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":61},"end":{"line":20,"column":145}}})) != null ? stack1 : "")
    + "\" style=\"cursor: not-allowed\">\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"user_profile") : stack1)) != null ? lookupProperty(stack1,"is_vaccinated") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":21,"column":85}}})) != null ? stack1 : "")
    + "\n            <div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"formFootnote")), depth0))
    + " mt-1 "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"flexGap2")), depth0))
    + "\">\n              <i class=\"fas fa-exclamation-circle _icon "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"icon")), depth0))
    + " text-info\"></i>\n              <div>Make sure Parker has your COVID vaccination status.</div>\n            </div>\n          </div>\n        </div>\n        <div>\n          <label class=\"prompt-label mb-2\">VAX Verified Monkeypox:</label>\n          <div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"vaxStatus")), depth0))
    + " _vax-status "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"user_profile") : stack1)) != null ? lookupProperty(stack1,"is_vaccinated_mp") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":61},"end":{"line":30,"column":148}}})) != null ? stack1 : "")
    + "\" style=\"cursor: not-allowed\">\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"user_profile") : stack1)) != null ? lookupProperty(stack1,"is_vaccinated_mp") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":31,"column":12},"end":{"line":31,"column":88}}})) != null ? stack1 : "")
    + "\n            <div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"formFootnote")), depth0))
    + " mt-1 "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"flexGap2")), depth0))
    + "\">\n              <i class=\"fas fa-exclamation-circle _icon "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"icon")), depth0))
    + " text-info\"></i>\n              <div>Make sure Parker has your monkeypox vaccination status.</div>\n            </div>\n          </div>\n        </div>\n        <div>\n          <label class=\"prompt-label mb-2\">Email:<span class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fgSuperscript")), depth0))
    + " text-success\">Private</span></label>\n          <div style=\"cursor: not-allowed;\">"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"user_profile") : stack1)) != null ? lookupProperty(stack1,"email") : stack1), depth0))
    + "</div>\n        </div>\n        <div>\n          <form id=\"newPassword\">\n            <div class=\"_new-password "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"newPassword")), depth0))
    + " "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"flexGap3")), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"user_profile") : stack1)) != null ? lookupProperty(stack1,"password_exists") : stack1),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":14},"end":{"line":50,"column":25}}})) != null ? stack1 : "")
    + "              <label class=\"prompt-label\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"user_profile") : stack1)) != null ? lookupProperty(stack1,"password_exists") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":51,"column":42},"end":{"line":51,"column":111}}})) != null ? stack1 : "")
    + " Password:</label>\n              <div class=\"mt-1\">\n                <input data-email=\""
    + ((stack1 = alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"user_profile") : stack1)) != null ? lookupProperty(stack1,"email") : stack1), depth0)) != null ? stack1 : "")
    + "\" autocomplete=\"new-password\" class=\"_fg-input-change-password form-control "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"mono")), depth0))
    + " handle-input\" type=\"password\" data-module=\"handle_validate_password\"\n                       data-parent=\"._new-password\" name=\"password\">\n              </div>\n              <small class=\"_password-instructions "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"shrink")), depth0))
    + " text-center\">\n                Make sure it's at least 12 characters OR at least 8 characters including a number and a lower case letter.\n              </small>\n              <div class=\"_password-buttons "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"passwordButtons")), depth0))
    + "\">\n                <button data-attendee-id=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"a_id") : stack1), depth0))
    + "\" class=\"btn btn-success _fg-btn-change-password disabled handle-click\" data-module=\"handle_change_password\">Set New Password</button>\n                <button class=\"btn btn-default _show-password d-none animate animated fadeIn handle-click\" data-module=\"handle_show_password\">Show Password</button>\n              </div>\n              <div class=\"_fg-alert "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fgAlert_danger")), depth0))
    + " _feedback-alert d-none\"></div>\n            </div>\n          </form>\n        </div>\n      </div>\n      <hr id=\"_your-images\" class=\"my-3\" />\n    </div>\n  </div>\n  <div>\n    <div>\n      <h3 class=\"sub-prompt "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"center")), depth0))
    + " my-4\">Your Images</h3>\n      <div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"center")), depth0))
    + " mb-4\">\n        <span class=\"my-4 d-block\">\n          <a href=\"#_upload-images\" class=\"btn btn-default outline\">\n            <i class=\"fas fa-plus mr-2\"></i>Add more pics\n          </a>\n        </span>\n      </div>\n      <div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fullWidthSm")), depth0))
    + " text-center\">\n\n        <div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fgAlertSm_info")), depth0))
    + " _fg-pic-alert-pending-approval d-none\">\n          <i class=\"fas fa-info-circle _icon "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"icon")), depth0))
    + "\"></i>\n          <span>Your pics are pending approval. Once approved you will be able to see the other guys attending</span>\n        </div>\n\n        <div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fgAlertSm_info")), depth0))
    + " _fg-pic-alert-in-grid-tip d-none\">\n          <i class=\"fas fa-info-circle _icon "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"icon")), depth0))
    + "\"></i>\n          <span>Tip: Your pics will not appear in the public grid unless they are approved and your reply is \"Yes\" or \"Maybe\"</span>\n        </div>\n\n        <div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fgAlertSm_info")), depth0))
    + " _fg-pic-alert-no-pics d-none\">\n          <i class=\"fas fa-exclamation-circle _icon "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"icon")), depth0))
    + "\"></i>\n          <span>You have no pics or none are showing. Add some and you will be able to see the other guys who RSVP and are attending.</span>\n        </div>\n\n        <div id=\"_profile-images\">\n          <!-- Manage Secondary Images -->\n"
    + ((stack1 = container.invokePartial(require("../partials/image-management.handlebars"),depth0,{"name":"image-management","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n      </div>\n    </div>\n  </div>\n"
    + ((stack1 = container.invokePartial(require("../partials/dropzone.handlebars"),depth0,{"name":"dropzone","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});