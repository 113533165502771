var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div>\n          <input class=\"form-check-input filter-reply\" type=\"checkbox\" name=\"checkboxFilterByReply\" id=\"filterReply-"
    + alias2(alias1((data && lookupProperty(data,"key")), depth0))
    + "\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"int") : depth0), depth0))
    + "\">\n          <label class=\"form-check-label mr-4\" for=\"filterReply-"
    + alias2(alias1((data && lookupProperty(data,"key")), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"str") : depth0), depth0))
    + "</label>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias2(__default(require("../helpers/log.js")).call(alias1,"~~~~~~~ rendering launch-panel-bulk-email",depth0,{"name":"log","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":56}}}))
    + "\n\n<div class=\"_fg-bulk "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fgBulk")), depth0))
    + "\">\n  <label>Criteria:</label>\n  <div>\n"
    + ((stack1 = container.invokePartial(require("../partials/party-select-dropdown.handlebars"),depth0,{"name":"party-select-dropdown","hash":{"active_only":true,"selector":"_partySelectDropdownBulkEmail"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <input class=\"form-control\" name=\"rating\" type=\"text\" value=\"\" placeholder=\"Rating equals\">\n    <input class=\"form-control\" name=\"term_slug\" type=\"text\" placeholder=\"Term Slug Filter\">\n  </div>\n  <div>\n    <div class=\"form-check "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"flexGapWrap3")), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,__default(require("../helpers/globals.js")).call(alias1,"REPLY",{"name":"globals","hash":{},"data":data,"loc":{"start":{"line":12,"column":14},"end":{"line":12,"column":31}}}),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":6},"end":{"line":17,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n  </div>\n  <label class=\"mt-4\">Modifiers:</label>\n  <div>\n    <input class=\"form-control\" name=\"commit\" type=\"text\" value=\"\" placeholder=\"Type the word --commit-- to actually send\">\n    <input class=\"form-control\" name=\"batch_size\" type=\"text\" placeholder=\"Batch size (default 20)\">\n  </div>\n  <label class=\"mt-4\">Generic Email Only:</label>\n  <button class=\"btn btn-default outline clear-generic-indicators d-block\">Clear Generic Indicators</button>\n  <div>\n    <input class=\"form-control\" name=\"subject\" type=\"text\" placeholder=\"Subject\">\n  </div>\n  <div>\n    <textarea name=\"message\" class=\"_fg-generic-body form-control\" rows=\"5\" aria-required=\"false\" aria-invalid=\"false\"\n              placeholder=\"Leave empty for templated generic email, otherwise this message will be sent. DO NOT sign -Parker otherwise two will print. HTML is ok.\"></textarea>\n  </div>\n  <div>\n    <textarea name=\"message_sms\" class=\"_fg-generic-body-sms form-control\" rows=\"3\" aria-required=\"false\" aria-invalid=\"false\" placeholder=\"SMS message will only be sent if not empty and ignored for all but generic inline\"></textarea>\n  </div>\n  <div class=\"mt-4\">\n    <div class=\"form-check form-check-inline\">\n      <input class=\"form-check-input query-recipients\" type=\"checkbox\" name=\"checkboxQueryRecipients\" id=\"queryRecipients\">\n      <label class=\"form-check-label mr-4\" for=\"queryRecipients\">Query Recipients</label>\n    </div>\n  </div>\n  <div class=\"mt-4\">\n    <div><button class=\"btn btn-danger _fg-set-brakes _ajax-override handle-click\" data-module=\"handle_emergency_brakes\">Set the Brakes</button></div>\n  </div>\n\n  <div id=\"bulkActionButtons\" class=\"mt-4\">\n"
    + ((stack1 = container.invokePartial(require("./bulk-action-buttons.handlebars"),depth0,{"name":"bulk-action-buttons","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n\n  <div id=\"batchWorking\" class=\"mt-3 "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"flexGapColumn2")), depth0))
    + " "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"sm")), depth0))
    + " text-center\"></div>\n  <span class=\"_fg-bulk-stats "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fgBulkStats")), depth0))
    + " mt-4\"></span>\n  <div id=\"bulkQueryResults\" class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"recipientsGrid")), depth0))
    + " my-4\"></div>\n  <div class=\"_fg-envelope-results\"></div>\n</div>";
},"usePartial":true,"useData":true});