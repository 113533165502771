export default function(type) {
  switch (type) {
    case 'login':
      return 'Get In';
    case 'password':
      return 'Reset Password';
    case 'password-reset':
      return 'Set Your Password';
    default:
  }
  return 'Get In';
}
