var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    Invalid or unknown Attendee\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    Invalid or unknown Attendee, provide e-mail to the door person\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"h3\">\n"
    + ((stack1 = __default(require("../helpers/ifCond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"attempt") : depth0),"<",3,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":4,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../helpers/ifCond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"attempt") : depth0),">",2,{"name":"ifCond","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":2},"end":{"line":7,"column":13}}})) != null ? stack1 : "")
    + "</div>\n"
    + ((stack1 = container.invokePartial(require("./tryAgain.handlebars"),depth0,{"name":"tryAgain","hash":{"again":true},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});