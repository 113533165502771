import Globals from '@lib/globals';
export default function( event_name, event_value ) {
  if ( event_name == 'wpcf-rsvp-reply' ) {
    switch (event_value) {
      case Globals.REPLY.no.public:
        return Globals.STYLES.danger;
      case Globals.REPLY.firm_yes.str:
      case Globals.REPLY.fresh_yes.str:
        return Globals.STYLES.success;
      case Globals.REPLY.maybe.str:
        return Globals.STYLES.info;
      case Globals.REPLY.wait_list.str:
        return Globals.STYLES.warning;
    }
  }
}
