export default function() {
  var subsection_classes = [],
    p_id = this.response.p_id,
    active_party = this.response.active_parties[ p_id ],
    partyIsFirstClass = active_party.is_party_first_class,
    userIsFirstClass = active_party.is_first_class_user,
    partyIsOnIce = active_party.is_party_on_ice,
    partyIsOver = active_party.is_party_over;

  if ( partyIsOnIce || ( ! userIsFirstClass && partyIsFirstClass ) ) {
    subsection_classes.push( '_on-ice' );
    subsection_classes.push( this.styles.onIce );
  }
  if ( partyIsOver ) {
    subsection_classes.push( this.styles.partyOver );
  }
  return subsection_classes.join( ' ' );
}
