export default function( bool ) {
  if ( null === bool ) return false;
  switch (typeof bool) {
    case 'undefined':
    case 'boolean':
      return bool;
      break;
    case 'string':
    case 'number':
      return parseInt(bool) !== 0;
      break;
    case 'object':
      return Array.isArray(bool) ? bool.length : Object.values(bool).length;
      break;
  }
}