import Globals from '@lib/globals';
export default function(reply_n) {
  const r = Globals.REPLY;
  var atts = [];
  switch ( parseInt( reply_n ) ) {
    case r.firm_yes.int:
    case r.fresh_yes.int:
      atts.push('text-success');
      atts.push('fas');
      atts.push('fa-thumbs-up');
      break;
    case r.no_reply.int:
      atts.push('far');
      atts.push('fa-hourglass');
      break;
    case r.wait_list.int:
      atts.push('text-warning');
      atts.push('far');
      atts.push('fa-clock');
      break;
    case r.maybe.int:
      atts.push('text-accent-color');
      atts.push('fas');
      atts.push('fa-question-circle');
      break;
    case r.no.int:
      atts.push('text-danger');
      atts.push('fas');
      atts.push('fa-thumbs-down');
      break;
    default:
  }
  return atts.join(' ');
}
