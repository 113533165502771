import Globals from '@lib/globals';

export default function( overlay_atts ) {
  let classes = "img-fluid mx-auto";
  if ( overlay_atts.overlay && overlay_atts.blur ) {
    classes += " " + overlay_atts.blur;
  }
  let image_atts = {
    class: classes,
    title: overlay_atts.tooltip,
    "data-toggle": "tooltip",
  };
  let atts = [];
  $.each( image_atts, (key, value) => {
    atts.push(key + "=" + value);
  });
  return atts.join(" ");
}
