export default function(str, position) {
  position = position || 10;

  const len = str.length,
         cut = str.substring(0, position);

  if ( position < len ) {
    return cut + " ...";
  }
  return cut;
}
