// filter and return objects within an array of objects, using one of the objects' key/value pairs
// If value is an array, it will check all of them
export default function(data, key, value) {
  if ( Array.isArray( data ) ) {
    if ( Array.isArray( value ) ) {
      return data.filter( rec => value.includes( rec[key] ) );
    }
    return data.filter( rec => rec[key] == value );
  }
}
