export default function(gb, styles) {
  const icon_lookup = {};

  icon_lookup[styles.fgReminded] = 'fa-hourglass-start';
  icon_lookup[styles.fgIconPending] = 'fa-hourglass';
  icon_lookup[styles.fgDelisted] = 'fa-thumbs-down';

  var overlays = [];

  if ( gb.title && gb.title.indexOf('REMINDED') > -1) {
    overlays.push(styles.fgReminded);
  }
  if (gb.post_status && gb.post_status == 'pending') {
    overlays.push(styles.fgIconPending);
  }
  if (gb.rating === 0 || (gb.rating && gb.rating < 3) ) {
    overlays.push(styles.fgDelisted);
  }

  // get the last one
  var state = overlays.slice(-1);

  if (state.length) {
    var classes = [
      state[0],
      'far',
      icon_lookup[state],
      '_icon',
      '_icon-overlay',
      '_publish-status'
    ];
    var classes_str = classes.filter(Boolean).join(' ');
    var the_icon = `<i class="${classes_str}"></i>`;
    return the_icon;
  }

  return '';
}
