var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"_fg-enlarge\" class=\"animated fadeIn "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fgEnlarge")), depth0))
    + " handle-click\" data-module=\"handle_close_enlarged_image\">\n    <div class=\"_fg-enlarge-wrapper "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"enlargeWrapper")), depth0))
    + "\">\n        <div>\n          <picture>\n          </picture>\n          <span class=\"_fg-close "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fgClose")), depth0))
    + " btn btn-link btn-sm handle-click\" data-module=\"handle_close_enlarged_image\"><i class=\"far fa-times-circle "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"redShadow")), depth0))
    + "\"></i></span>\n        </div>\n    </div>\n</div>\n";
},"useData":true});