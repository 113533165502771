var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/length.js")).call(alias1,lookupProperty(helpers,"lookup").call(alias1,(depth0 != null ? lookupProperty(depth0,"picture_data") : depth0),(depth0 != null ? lookupProperty(depth0,"a_id") : depth0),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":5,"column":18},"end":{"line":5,"column":44}}}),{"name":"length","hash":{},"data":data,"loc":{"start":{"line":5,"column":10},"end":{"line":5,"column":45}}}),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":26,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div data-modified=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"modified") : depth0), depth0))
    + "\" data-post-name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"post_name") : depth0), depth0))
    + "\" class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"gridBrick")), depth0))
    + " _grid__brick\" data-attendee-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"a_id") : depth0), depth0))
    + "\">\n        <div class=\"_fg-thumb "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fgThumb")), depth0))
    + "\" data-reply=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"reply_n") : depth0), depth0))
    + "\" data-rsvp-count=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"rsvp_count") : depth0), depth0))
    + "\" data-terms=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"terms") : depth0), depth0))
    + "\">\n          <div class=\"_fg-single-image-public "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"singleImagePublic")), depth0))
    + " _fg-image-wrap handle-click "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fgImageWrap")), depth0))
    + "\" data-module=\"handle_public_grid_detail\" data-face-allowed=\""
    + ((stack1 = lookupProperty(helpers,"with").call(alias3,lookupProperty(helpers,"lookup").call(alias3,(depth0 != null ? lookupProperty(depth0,"picture_data") : depth0),(depth0 != null ? lookupProperty(depth0,"a_id") : depth0),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":8,"column":206},"end":{"line":8,"column":232}}}),{"name":"with","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":198},"end":{"line":8,"column":262}}})) != null ? stack1 : "")
    + "\"\n            data-attendee-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"a_id") : depth0), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias3,lookupProperty(helpers,"lookup").call(alias3,(depth0 != null ? lookupProperty(depth0,"picture_data") : depth0),(depth0 != null ? lookupProperty(depth0,"a_id") : depth0),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":10,"column":46}}}),{"name":"with","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":14,"column":21}}})) != null ? stack1 : "")
    + "            <div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"captionShadow")), depth0))
    + " _fg-caption-shadow\"></div>\n            <div class=\"_fg-caption "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fgCaption")), depth0))
    + "\" data-location=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"location") : depth0), depth0))
    + "\" data-joined=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"joined") : depth0), depth0))
    + "\" data-first-name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"first_name") : depth0), depth0))
    + "\">\n"
    + ((stack1 = container.invokePartial(require("./grid-caption.handlebars"),depth0,{"name":"grid-caption","data":data,"indent":"              ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n          </div>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,__default(require("../helpers/length.js")).call(alias3,"body.attendee-master",{"name":"length","hash":{},"data":data,"loc":{"start":{"line":21,"column":14},"end":{"line":21,"column":45}}}),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":24,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"meta") : depth0)) != null ? lookupProperty(stack1,"allow_face") : stack1), depth0));
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(alias1,__default(require("../helpers/get.js")).call(alias1,0,__default(require("../helpers/filter.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"images") : depth0),"is_primary",true,{"name":"filter","hash":{},"data":data,"loc":{"start":{"line":11,"column":29},"end":{"line":11,"column":62}}}),{"name":"get","hash":{},"data":data,"loc":{"start":{"line":11,"column":22},"end":{"line":11,"column":63}}}),{"name":"with","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":14},"end":{"line":13,"column":23}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./picture.handlebars"),depth0,{"name":"picture","hash":{"grid":((stack1 = (depth0 != null ? lookupProperty(depth0,"render") : depth0)) != null ? lookupProperty(stack1,"grid") : stack1)},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"form-check _check-choose "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"checkChoose")), depth0))
    + "\"><input class=\"form-check-input\" type=\"checkbox\" data-attendee-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"a_id") : depth0), depth0))
    + "\"></div>\n          <div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"attendeeDetail")), depth0))
    + "\"><i class=\"fas fa-search-plus fa-2x\"></i></div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div id=\"lastGridCell\"\n         class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"flexGapColumn0")), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"lastGridItem")), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"singleImagePublic")), depth0))
    + "\">\n      <img src=\""
    + ((stack1 = __default(require("../helpers/missingImage.js")).call(alias3,{"name":"missingImage","hash":{},"data":data,"loc":{"start":{"line":32,"column":16},"end":{"line":32,"column":34}}})) != null ? stack1 : "")
    + "\"\n           class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"center")), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"block")), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"cover")), depth0))
    + "\">\n      <div class=\"px-2\">Additional ("
    + alias2(__default(require("../helpers/countMissingImages.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"public_grid") : stack1),{"name":"countMissingImages","hash":{},"data":data,"loc":{"start":{"line":34,"column":36},"end":{"line":34,"column":79}}}))
    + ") without pics</div>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias2(__default(require("../helpers/log.js")).call(alias1,"~~~~~~~ rendering public-grid",depth0,{"name":"log","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":44}}}))
    + "\n\n<div class=\"_fg-grid-wrapper _fli "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"gridWrapper")), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"public_grid") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":27,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/countMissingImages.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"public_grid") : stack1),{"name":"countMissingImages","hash":{},"data":data,"loc":{"start":{"line":29,"column":8},"end":{"line":29,"column":49}}}),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":2},"end":{"line":36,"column":9}}})) != null ? stack1 : "")
    + "</div>\n<div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"publicGridProgress")), depth0))
    + " text-primary\"><i class=\"fas fa-circle-notch fa-2x fa-spin\"></i></div>\n";
},"usePartial":true,"useData":true});