var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias1(__default(require("../helpers/log.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"~~~~~~~ rendering launch-panel-purge",depth0,{"name":"log","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":51}}}))
    + "\n\n<div class=\""
    + alias1(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"flexGapCenter4")), depth0))
    + "\">\n  <div><button class=\"btn btn-danger handle-click\" data-module=\"handle_purge_nonces\">Delete Nonces</button></div>\n  <div><button class=\" btn btn-danger handle-click\"  data-module=\"handle_purge_shortlinks\">Delete Shortlinks</button></div>\n  <div><button class=\" btn btn-primary handle-click\"  data-module=\"handle_load_percentiles\">Refresh Percentiles</button></div>\n</div>\n<div class=\"_purge-results\"></div>";
},"useData":true});