var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "Message the attendee:";
},"3":function(container,depth0,helpers,partials,data) {
    return "Optionally message Parker:";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div id=\"_chatContainer-"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"rsvp_notes") : stack1)) != null ? lookupProperty(stack1,"meta") : stack1)) != null ? lookupProperty(stack1,"r_id") : stack1), depth0))
    + "\"\n         class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"chatContainer")), depth0))
    + " mt-2 mb-4\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"rsvp_notes") : stack1)) != null ? lookupProperty(stack1,"data") : stack1),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":6},"end":{"line":9,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./chat-single-message.handlebars"),depth0,{"name":"chat-single-message","hash":{"response":depth0},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../helpers/log.js")).call(alias1,"~~~~~~~ rendering chat-rsvp-note-container",depth0,{"name":"log","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":57}}}))
    + "\n<label class=\"prompt-label\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/utils.js")).call(alias1,"admin_logged_in",{"name":"utils","hash":{},"data":data,"loc":{"start":{"line":2,"column":34},"end":{"line":2,"column":59}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":28},"end":{"line":2,"column":123}}})) != null ? stack1 : "")
    + "</label>\n<div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/length.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"rsvp_notes") : stack1)) != null ? lookupProperty(stack1,"data") : stack1),{"name":"length","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":41}}}),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":11,"column":9}}})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./chat-rsvp-note-form.handlebars"),depth0,{"name":"chat-rsvp-note-form","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});