// must be an array of single level objects. if it starts out as an object, will attempt to convert
// if no key is provided, it's considered a flat regular array
export default function( data, key, asc ) {
  const sortFunc = (a, b) => {
    const val_a = key ? a[key] : a,
          val_b = key ? b[key] : a;
    return asc ? val_a - val_b : val_b - val_a;
  },
  // Convert booleans to numbers to make them sortable
  convertBooleans = (row) => {
    if ( row[key] === true ) {
      row[key] = 1;
    }
    if ( row[key] === false ) {
      row[key] = 0;
    }
    return row;
  };

  if ( typeof data == 'object') {
    return Object.entries(data).map( ([key, value]) => {
      // Add the original object's key (not the sort key) to the object so we don't lose it
      value["key"] = key;
      return value;
    }).map(convertBooleans).sort( sortFunc );
  }
  if ( Array.isArray( data ) ) {
    return data.map(convertBooleans).sort( sortFunc );
  }
  return data;
}
