var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "      <h4>Something went wrong. Please reply to the original email to unsubscribe.</h4>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"display-3\">Are you sure?</div>\n      <div class=\"_will-be-removed "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"willBeRemoved")), depth0))
    + "\">You will be removed from all future party invitations.</div>\n      <div class=\"_smile-frown "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"h2")), depth0))
    + "\">\n        <i class=\"far fa-frown display-3\"></i>\n      </div>\n      <div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"flexGapCenter3")), depth0))
    + "\">\n        <button\n          class=\"btn btn-danger btn-lg handle-click\"\n          data-module=\"handle_unsubscribe\"\n          data-attendee-id=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"attendee_by_email") : stack1)) != null ? lookupProperty(stack1,"a_id") : stack1), depth0))
    + "\"\n        >Yes, I'm sure</button>\n        <button\n          class=\"btn btn-success btn-lg handle-click\"\n          data-module=\"handle_changed_mind\"\n          href=\"#\"\n        >No, I changed my mind</button>\n      </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias2(__default(require("../helpers/log.js")).call(alias1,"~~~~~~~ rendering remove",depth0,{"name":"log","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":39}}}))
    + "\n<section\n  id=\"unsubscribe\"\n  class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"unsubscribe")), depth0))
    + " "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fullWidthSm")), depth0))
    + " "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"hotelBed")), depth0))
    + "\"\n>\n  <div id=\"_unsub_ui\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/not.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"attendee_by_email") : stack1)) != null ? lookupProperty(stack1,"a_id") : stack1),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":7,"column":10},"end":{"line":7,"column":47}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"attendee_by_email") : stack1)) != null ? lookupProperty(stack1,"a_id") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":28,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n  <div>\n    <div\n      id=\"_unsub-success\"\n      class=\"display-4 d-none text-success\"\n    >You've been successfully unsubscribed</div>\n    <div\n      id=\"_ubsub-failure\"\n      class=\"d-none display-4 text-danger \"\n    >Something went wrong. Please email <a href=\"mailto:wantgroup@wantgroup.men\">wantgroup@wantgroup.men</a> to request removal.</h4>\n    </div>\n  </div>\n</section>";
},"useData":true});