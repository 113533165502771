var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"bulkCell")), depth0))
    + "\">\n      <div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"gridBrick")), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"picture_data") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":7,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("../partials/ratings.handlebars"),depth0,{"name":"ratings","hash":{"hover":true,"a_id":(depth0 != null ? lookupProperty(depth0,"a_id") : depth0),"rating":(depth0 != null ? lookupProperty(depth0,"rating") : depth0)},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        <div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"scoreBadgeHover")), depth0))
    + "\">"
    + ((stack1 = container.invokePartial(require("../partials/score-badge.handlebars"),depth0,{"name":"score-badge","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n      </div>\n      <span>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span>\n      <span class=\"text-primary\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"relay_timestamp_fmt") : depth0), depth0))
    + "</span>\n      <span>"
    + alias2(__default(require("../helpers/xrefHuman.js")).call(alias3,"NOTIFICATION_STATUS",(depth0 != null ? lookupProperty(depth0,"notification_status_n") : depth0),{"name":"xrefHuman","hash":{},"data":data,"loc":{"start":{"line":13,"column":12},"end":{"line":13,"column":69}}}))
    + "</span>\n      <span>"
    + alias2(__default(require("../helpers/xrefHuman.js")).call(alias3,"REPLY",(depth0 != null ? lookupProperty(depth0,"reply_n") : depth0),{"name":"xrefHuman","hash":{},"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":14,"column":41}}}))
    + "</span>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../partials/picture.handlebars"),depth0,{"name":"picture","hash":{"grid":(depth0 != null ? lookupProperty(depth0,"grid") : depth0)},"data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../helpers/log.js")).call(alias1,"~~~~~~~ rendering bulk-query-results",depth0,{"name":"log","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":51}}}))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,__default(require("../helpers/sortObjects.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"query_results") : stack1),"relay_timestamp",true,{"name":"sortObjects","hash":{},"data":data,"loc":{"start":{"line":2,"column":10},"end":{"line":2,"column":70}}}),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":16,"column":11}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});