var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"newPassword\">\n  <div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"newPassword")), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"center")), depth0))
    + " _new-password\">\n      <div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"changePasswordScreen")), depth0))
    + " _fg-change-password\">\n          <input\n            autocapitalize=\"none\"\n            autocomplete=\"new-password\"\n            class=\"_fg-input-change-password form-control "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"mono")), depth0))
    + " handle-input\"\n            type=\"password\" name=\"password\"\n            data-module=\"handle_validate_password\"\n            data-parent=\"._new-password\"\n            placeholder=\"New Password\">\n          <input\n            autocapitalize=\"none\"\n            autocomplete=\"new-password\"\n            class=\"_fg-input-change-password-confirm form-control "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"mono")), depth0))
    + " handle-input\"\n            type=\"password\"\n            data-module=\"handle_validate_password\"\n            data-parent=\"._new-password\"\n            name=\"password\"\n            placeholder=\"Confirm Password\">\n          <small class=\"_password-instructions "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"shrink")), depth0))
    + " text-center\">\n            Make sure it's at least 12 characters OR at least 8 characters including a number and a lower case letter.\n          </small>\n          <div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"passwordFeedback")), depth0))
    + " _password-feedback invisible text-center "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"shrink")), depth0))
    + "\"></div>\n          <div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"passwordButtons")), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"shrink")), depth0))
    + " _password-buttons\">\n            <button\n              data-attendee-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"a_id") : depth0), depth0))
    + "\"\n              class=\"btn btn-success _fg-btn-change-password disabled handle-click form-control\"\n              data-module=\"handle_change_password\"\n              data-parent=\"._new-password\">Set Password</button>\n            <button\n              class=\"btn btn-default _show-password d-none animate animated fadeIn handle-click\"\n              data-module=\"handle_show_password\">Show Password</button>\n          </div>\n          <div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fgAlert_success")), depth0))
    + " _feedback-alert d-none\"></div>\n          <div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"center")), depth0))
    + " _login-now d-none\">\n            <a class=\"p-3 btn btn-link\" href=\""
    + ((stack1 = __default(require("../helpers/home_url.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"/reset/?t=login",{"name":"home_url","hash":{},"data":data,"loc":{"start":{"line":37,"column":46},"end":{"line":37,"column":78}}})) != null ? stack1 : "")
    + "\" class=\"btn btn-link d-none\">Login Now</a>\n          </div>\n          <div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fgAlert_danger")), depth0))
    + " d-none _cookie-check\">Cookies must be enabled before logging in</div>\n      </div>\n  </div>\n</form>\n";
},"useData":true});