var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias2(__default(require("../helpers/log.js")).call(alias1,"~~~~~~~ rendering login",depth0,{"name":"log","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":38}}}))
    + "\n<form id=\"loginAttendee\" action=\""
    + alias2(__default(require("../helpers/home_url.js")).call(alias1,"/getin/",{"name":"home_url","hash":{},"data":data,"loc":{"start":{"line":2,"column":33},"end":{"line":2,"column":55}}}))
    + "\" method=\"POST\">\n  <div>\n    <div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"loginComponent")), depth0))
    + "\">\n      <h3 class=\"text-left\"><i class=\"fas fa-lock\" style=\"margin-right: 10px;\"></i> Login</h3>\n      <input\n        type=\"email\"\n        name=\"login-email\"\n        autocomplete=\"username\"\n        autocapitalize=\"none\"\n        value=\"\"\n        class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"mono")), depth0))
    + " form-control check-version handle-input\"\n        aria-required=\"true\"\n        data-target=\"#submitOnEnter\"\n        data-module=\"handle_noop\"\n        data-parent=\"#loginAttendee\"\n        aria-invalid=\"false\"\n        placeholder=\"E-Mail\">\n      <input\n        type=\"password\"\n        name=\"login-password\"\n        autocomplete=\"current-password\"\n        autocapitalize=\"none\"\n        value=\"\"\n        data-target=\"#submitOnEnter\"\n        data-module=\"handle_noop\"\n        class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"mono")), depth0))
    + " form-control handle-input\"\n        aria-required=\"true\"\n        data-parent=\"#loginAttendee\"\n        aria-invalid=\"false\"\n        placeholder=\"Password\">\n      <div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"shrink")), depth0))
    + " text-center\">\n        <a\n          class=\"btn btn-link handle-click\"\n          href=\""
    + ((stack1 = __default(require("../helpers/home_url.js")).call(alias1,"/reset/?t=password&fragment=profile",{"name":"home_url","hash":{},"data":data,"loc":{"start":{"line":35,"column":16},"end":{"line":35,"column":68}}})) != null ? stack1 : "")
    + "\"\n          data-module=\"handle_navigate\"\n          data-template=\"reset\">\n          <span>Forgot or need your password?</span>\n        </a>\n      </div>\n      <span style=\"cursor: not-allowed; width: 100%\">\n        <button\n          id=\"submitOnEnter\"\n          class=\"btn btn-legiblue btn-lg handle-click touch-pulse btn-block\"\n          data-module=\"handle_user_login\"\n          data-parent='#loginAttendee'\n          data-fragment=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"fragment") : depth0), depth0))
    + "\"\n          data-redirect=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"login_redirect") : depth0), depth0))
    + "\">Sign In</button>\n      </span>\n      <span style=\"flex: 0\">\n        <div class=\"_fg-alert "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fgAlert_danger")), depth0))
    + " d-none _cookie-check mt-0\">Cookies must be enabled before logging in</div>\n      </span>\n    </div>\n  </div>\n</form>\n\n"
    + ((stack1 = container.invokePartial(require("./sms.handlebars"),depth0,{"name":"sms","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});