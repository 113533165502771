var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias1(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"formFootnote")), depth0))
    + " text-muted\">\n  <span><i class=\"fas fa-exclamation-circle _icon\"></i></span>\n  <div>"
    + alias1(((helper = (helper = lookupProperty(helpers,"footnote") || (depth0 != null ? lookupProperty(depth0,"footnote") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"footnote","hash":{},"data":data,"loc":{"start":{"line":3,"column":7},"end":{"line":3,"column":19}}}) : helper)))
    + "</div>\n</div>\n";
},"useData":true});