// Joins any number of strings, if empty skips
export default function( delim ) {
  delim = delim || ',';
  let args = [ ...arguments ],
      buffer = [];
  for (var i = 1; i < (args.length - 1); i++) {
    buffer.push( args[i] );
  }
  if ( Array.isArray( args[1] ) ) {
    buffer = args[1];
  }
  return buffer.filter(Boolean).join(delim);
}
