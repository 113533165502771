export default function (dateObj, format) {

  dateObj = dateObj == 'today' ? new Date() : new Date(dateObj);

  const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayName = days[dateObj.getDay()],
    monthName = months[dateObj.getMonth()];

  const formats = {
    YYYY: dateObj.getFullYear(),
    MM: (dateObj.getMonth() + 1 ) < 10 ? ('0' + (dateObj.getMonth() + 1)) : dateObj.getMonth() + 1,
    m: months[dateObj.getMonth()],
    DD: dateObj.getDate() < 10 ? '0' + dateObj.getDate() : dateObj.getDate(),
    d: days[dateObj.getDay()]
  };

  var formatted = format;

  Object.keys(formats).forEach((fmt, i) => {
    var re = new RegExp(fmt + '+');
    if (formatted.match(re)) {
      formatted = formatted.replace(re, formats[fmt]);
    }
  });

  return formatted;
}
