var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div data-modified=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"modified") : depth0), depth0))
    + "\" class=\"_grid__brick "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"gridBrick_rated")), depth0))
    + "\" data-rating=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"rating") : depth0), depth0))
    + "\" data-percentile=\""
    + alias2(__default(require("../helpers/defaultVal.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"percentile") : depth0),0,{"name":"defaultVal","hash":{},"data":data,"loc":{"start":{"line":3,"column":134},"end":{"line":3,"column":161}}}))
    + "\" data-post-status=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"post_status") : depth0), depth0))
    + "\">\n      <div class=\"_fg-thumb "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fgThumb")), depth0))
    + "\" data-terms=\""
    + alias2(__default(require("../helpers/implode.js")).call(alias3,",",(depth0 != null ? lookupProperty(depth0,"tags") : depth0),{"name":"implode","hash":{},"data":data,"loc":{"start":{"line":4,"column":66},"end":{"line":4,"column":86}}}))
    + "\">\n        <div class=\"_fg-single-image-public "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"singleImagePublic")), depth0))
    + " _fg-image-wrap "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fgImageWrap")), depth0))
    + " handle-click\" data-module=\"handle_load_admin_grid_detail\">\n"
    + ((stack1 = container.invokePartial(require("../partials/picture.handlebars"),depth0,{"name":"picture","hash":{"grid":__default(require("../helpers/featuredImage.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"a_id") : depth0),"grid",{"name":"featuredImage","hash":{},"data":data,"loc":{"start":{"line":6,"column":26},"end":{"line":6,"column":53}}})},"data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "          <div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"captionShadow")), depth0))
    + "\"></div>\n          <div class=\"_fg-caption "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fgCaption")), depth0))
    + "\">\n            <div data-email=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"searchable_terms") : depth0)) != null ? lookupProperty(stack1,"email") : stack1), depth0))
    + "\">\n              <span>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"display_name") : depth0), depth0))
    + "</span>\n              <span class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"captionPipe")), depth0))
    + "\">|</span>\n              <span data-age=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"age") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"age") : depth0), depth0))
    + "</span>\n              <span class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"captionPipe")), depth0))
    + "\">|</span>\n              <span class=\"_fag-position\" data-name=\""
    + alias2(__default(require("../helpers/lower.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"positions") : depth0),{"name":"lower","hash":{},"data":data,"loc":{"start":{"line":14,"column":53},"end":{"line":14,"column":72}}}))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"positions") : depth0), depth0))
    + "</span>\n            </div>\n          </div>\n        </div>\n        <span class=\"_fg-modal-on-demand\" data-attendee-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"a_id") : depth0), depth0))
    + "\" data-post-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"a_id") : depth0), depth0))
    + "\" data-admin=\"1\"></span>\n        "
    + ((stack1 = __default(require("../partials/atmOverlays.js")).call(alias3,depth0,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles")),{"name":"atmOverlays","hash":{},"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":19,"column":43}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = __default(require("../helpers/utils.js")).call(alias3,"get_rsvp_icon",(depth0 != null ? lookupProperty(depth0,"reply_n") : depth0),(depth0 != null ? lookupProperty(depth0,"a_id") : depth0),{"name":"utils","hash":{},"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":20,"column":49}}})) != null ? stack1 : "")
    + "\n      </div> <!-- End .fg-thumb -->\n"
    + ((stack1 = container.invokePartial(require("../partials/ratings.handlebars"),depth0,{"name":"ratings","hash":{"hover":true,"a_id":(depth0 != null ? lookupProperty(depth0,"a_id") : depth0),"rating":(depth0 != null ? lookupProperty(depth0,"rating") : depth0)},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      <div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"scoreBadgeHover")), depth0))
    + "\">"
    + ((stack1 = container.invokePartial(require("../partials/score-badge.handlebars"),depth0,{"name":"score-badge","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../helpers/log.js")).call(alias1,"~~~~~~~ rendering atm-grid",depth0,{"name":"log","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":41}}}))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"atm_grid") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":25,"column":11}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});