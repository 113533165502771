var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <span data-rating=\""
    + alias1(container.lambda((data && lookupProperty(data,"index")), depth0))
    + "\"\n        class=\"handle-click\"\n        data-module=\"handle_rating_click\">\n    <i class=\""
    + alias1(__default(require("../helpers/star.js")).call(alias2,__default(require("../helpers/defaultVal.js")).call(alias2,(depths[1] != null ? lookupProperty(depths[1],"rating") : depths[1]),((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"response"))) && lookupProperty(stack1,"rating")),{"name":"defaultVal","hash":{},"data":data,"loc":{"start":{"line":5,"column":21},"end":{"line":5,"column":65}}}),(data && lookupProperty(data,"index")),{"name":"star","hash":{},"data":data,"loc":{"start":{"line":5,"column":14},"end":{"line":5,"column":74}}}))
    + "\"></i>\n  </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(alias1,__default(require("../helpers/array.js")).call(alias1,0,1,2,3,4,5,{"name":"array","hash":{},"data":data,"loc":{"start":{"line":1,"column":8},"end":{"line":1,"column":27}}}),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":7,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});