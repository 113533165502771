var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fullWidthSm")), depth0))
    + "\">\n  <form id=\"_upload-images\" class=\"dropzone\" data-attendee-id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"a_id") : stack1), depth0))
    + "\"></form>\n  <div id=\"dzAlertContainer\"></div>\n</div>\n<div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fullWidthSm")), depth0))
    + " _fg-submit-cancel "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"dzSubmitCancel")), depth0))
    + " mb-5 mt-3\">\n  <button class=\"_fg-cancel-images btn btn-outline-light d-none\">Cancel</button>\n  <button class=\"_fg-submit-images btn btn-success d-none animated touchPulse infinite slower\">Upload Images</button>\n  <button class=\"_fg-upload-more btn btn-legiblue d-none\">Upload More</button>\n</div>\n<div id=\"dzBottom\"></div>\n";
},"useData":true});