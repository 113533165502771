var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"_image-management "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"imageManagementGrid")), depth0))
    + " _fg-image-grid "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"caption")), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"image_management") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":37,"column":13}}})) != null ? stack1 : "")
    + "  </div>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(alias1,__default(require("../helpers/sortObjects.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"images") : depth0),"is_primary",false,{"name":"sortObjects","hash":{},"data":data,"loc":{"start":{"line":11,"column":14},"end":{"line":11,"column":53}}}),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":6},"end":{"line":36,"column":15}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"_image-management-data\"\n             data-allow-face=\""
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"meta") : depths[1])) != null ? lookupProperty(stack1,"allow_face") : stack1), depth0))
    + "\"\n             data-primary-thumb-id=\""
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"meta") : depths[1])) != null ? lookupProperty(stack1,"primary_thumb_id") : stack1), depth0))
    + "\"\n             data-face-showing=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"face_showing") : depth0), depth0))
    + "\"\n             data-image-status=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"image_status") : depth0), depth0))
    + "\"\n             data-other-images-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"other_image_id") : depth0), depth0))
    + "\"\n             data-thumb-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"attach_id") : depth0), depth0))
    + "\"\n             data-attendee-id=\""
    + alias2(alias1((container.data(data, 1) && lookupProperty(container.data(data, 1),"key")), depth0))
    + "\"\n             data-title=\"Delete\">\n          <div onclick=\"$(this).toggleClass('"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"hover")), depth0))
    + "')\"\n               class=\"_fg-single-image-public "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"singleImagePublic")), depth0))
    + " position-relative"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"is_primary") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":98},"end":{"line":22,"column":168}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = container.invokePartial(require("./picture.handlebars"),depth0,{"name":"picture","hash":{"grid":((stack1 = (depth0 != null ? lookupProperty(depth0,"render") : depth0)) != null ? lookupProperty(stack1,"grid") : stack1)},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            "
    + ((stack1 = __default(require("../helpers/image-management/overlayIconIM.js")).call(alias3,(depths[1] != null ? lookupProperty(depths[1],"meta") : depths[1]),"overlay",{"name":"overlayIconIM","hash":{},"data":data,"loc":{"start":{"line":24,"column":12},"end":{"line":24,"column":49}}})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = __default(require("../helpers/image-management/overlayIconIM.js")).call(alias3,(depths[1] != null ? lookupProperty(depths[1],"meta") : depths[1]),"thumbTip",{"name":"overlayIconIM","hash":{},"data":data,"loc":{"start":{"line":25,"column":12},"end":{"line":25,"column":50}}})) != null ? stack1 : "")
    + "\n          </div>\n          <div class=\"_fg-other-images-manage mt-3 "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"otherImagesManage")), depth0))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,__default(require("../helpers/utils.js")).call(alias3,"admin_logged_in",{"name":"utils","hash":{},"data":data,"loc":{"start":{"line":27,"column":92},"end":{"line":27,"column":117}}}),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":86},"end":{"line":27,"column":147}}})) != null ? stack1 : "")
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"flexGapBlock0")), depth0))
    + "\">\n"
    + ((stack1 = container.invokePartial(require("./image-management-buttons.handlebars"),depth0,{"name":"image-management-buttons","hash":{"meta":(depths[1] != null ? lookupProperty(depths[1],"meta") : depths[1])},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "          </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"showFeaturedImg")),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":10},"end":{"line":34,"column":17}}})) != null ? stack1 : "")
    + "        </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"primary")), depth0))
    + " _primary-thumb";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"wrap")), depth0));
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"featuredImage")), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"center")), depth0))
    + "\">\n"
    + ((stack1 = container.invokePartial(require("./picture.handlebars"),depth0,{"name":"picture","hash":{"grid":__default(require("../helpers/featuredImage.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(container.data(data, 1) && lookupProperty(container.data(data, 1),"key")),"grid",{"name":"featuredImage","hash":{},"data":data,"loc":{"start":{"line":32,"column":30},"end":{"line":32,"column":60}}})},"data":data,"indent":"              ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias2(__default(require("../helpers/log.js")).call(alias1,"~~~~~~~ rendering image-management",depth0,{"name":"log","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":49}}}))
    + "\n\n\n"
    + alias2(__default(require("../helpers/setVar.js")).call(alias1,"showFeaturedImg",(depth0 != null ? lookupProperty(depth0,"showFeaturedImg") : depth0),{"name":"setVar","hash":{},"data":data,"loc":{"start":{"line":5,"column":0},"end":{"line":5,"column":44}}}))
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"response") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":0},"end":{"line":39,"column":9}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});