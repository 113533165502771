import Globals from '@lib/globals';

export default function(mapping, reply_n, notif_status, first_class_only) {
  const show_when = [
    {
      type: 'initial_invite',
      notification_status: [Globals.NOTIFICATION_STATUS.initialized.int],
      reply: [Globals.REPLY.no_reply.int],
      buttonColor: 'btn-default',
    },
    {
      type: 'reminder_yes_maybe',
      notification_status: [Globals.NOTIFICATION_STATUS.invited.int],
      reply: [Globals.REPLY.firm_yes.int, Globals.REPLY.fresh_yes.int, Globals.REPLY.maybe.int],
      buttonColor: 'btn-primary',
    },
    {
      type: 'reminder_no_reply',
      notification_status: [Globals.NOTIFICATION_STATUS.invited.int],
      reply: [Globals.REPLY.no_reply.int],
      buttonColor: 'btn-legiblue',
    },
    {
      type: 'room_number',
      notification_status: [
        Globals.NOTIFICATION_STATUS.confirmed.int,
        Globals.NOTIFICATION_STATUS.reminded.int,
        Globals.NOTIFICATION_STATUS.waitlisted.int // means you've been sent the off the waitlist email
      ],
      reply: [Globals.REPLY.firm_yes.int, Globals.REPLY.fresh_yes.int, Globals.REPLY.maybe.int],
      buttonColor: 'btn-success',
    },
    {
      type: 'wait_list',
      notification_status: [Globals.NOTIFICATION_STATUS.invited.int, Globals.NOTIFICATION_STATUS.reminded.int],
      reply: [Globals.REPLY.wait_list.int],
      buttonColor: 'btn-warning',
    },
  ];

  // Filter the one we need
  var theone = show_when.filter(rec => rec.notification_status.includes( parseInt( notif_status) ) && rec.reply.includes( parseInt( reply_n) ));
  if (theone.length) {
    var type = theone[0].type,
      button_title = mapping[type].title,
      button_class = mapping[type].class,
      button_color = theone[0].buttonColor;
    return {
      type: type,
      title: button_title,
      class: button_class,
      buttonColor: button_color,
    };
  }
  return false;
}
