export default function(percentile) {
  if (percentile > 75) {
    return 'text-success';
  } else if (percentile > 50) {
    return 'text-primary';
  } else if (percentile > 25) {
    return 'text-warning';
  }
  return 'text-danger';
}
