export default function(obj) {
  if ( !obj ) return false;
  if ( Array.isArray( obj ) ) {
    return obj.length;
  }
  if (typeof obj === 'string' || obj instanceof String) {
    return $(obj).length;
  }
  return Object.entries(obj).length;
}
