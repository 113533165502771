var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "link";
},"3":function(container,depth0,helpers,partials,data) {
    return "success";
},"5":function(container,depth0,helpers,partials,data) {
    return " touch-pulse";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("./increment.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"attempt") : depth0),{"name":"increment","hash":{},"data":data,"loc":{"start":{"line":7,"column":37},"end":{"line":7,"column":58}}}));
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"attempt") : depth0), depth0));
},"11":function(container,depth0,helpers,partials,data) {
    return "Try Again";
},"13":function(container,depth0,helpers,partials,data) {
    return "Check In";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button class=\"btn btn-"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"again") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":23},"end":{"line":4,"column":62}}})) != null ? stack1 : "")
    + " handle-click btn-lg"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"pulse") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":82},"end":{"line":4,"column":114}}})) != null ? stack1 : "")
    + "\"\n          data-module=\"handle_checkin\"\n          data-party=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"p_id") : depth0), depth0))
    + "\"\n          data-attempt=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"again") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":7,"column":24},"end":{"line":7,"column":84}}})) != null ? stack1 : "")
    + "\"\n          data-checksum=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"checksum") : depth0), depth0))
    + "\"\n          data-module=\"handle_checkin\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"again") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":9,"column":39},"end":{"line":9,"column":84}}})) != null ? stack1 : "")
    + "</button>\n";
},"useData":true});