// merges objects, any number of args
export default function() {
  let args = [...arguments],
    merged = {};
  for (var i = 0; i < args.length - 1; i++) {
    if (args[i]) {
      merged = { ...merged, ...args[i] };
    }
  }
  return merged;
}
