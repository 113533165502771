var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias2(__default(require("../helpers/log.js")).call(alias1,"~~~~~~~ rendering attendee-master",depth0,{"name":"log","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":48}}}))
    + "\n<section class=\"_fg-attendee-master "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"publicContainer")), depth0))
    + " "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"center")), depth0))
    + " "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"w100")), depth0))
    + "\" id=\"_selectedParty\" data-current-party=\""
    + alias2(__default(require("../helpers/utils.js")).call(alias1,"get_current_party",{"name":"utils","hash":{},"data":data,"loc":{"start":{"line":2,"column":156},"end":{"line":2,"column":185}}}))
    + "\" data-selected-party=\"false\">\n  <div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fullContent")), depth0))
    + "\">\n    <div class=\"_fg-attendee-master-section-main mt-5\">\n"
    + ((stack1 = container.invokePartial(require("./atm-filters.handlebars"),depth0,{"name":"atm-filters","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./atm-stats.handlebars"),depth0,{"name":"atm-stats","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      <div id=\"fg-main-grid\" class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fullWidthSm")), depth0))
    + " "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"noPadding")), depth0))
    + " "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"mainGrid")), depth0))
    + " position-relative\" data-party-id=\""
    + alias2(__default(require("../helpers/utils.js")).call(alias1,"get_selected_party",{"name":"utils","hash":{},"data":data,"loc":{"start":{"line":7,"column":152},"end":{"line":7,"column":182}}}))
    + "\">\n        <div id=\"atmGridData\" class=\"_fg-grid-wrapper "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"gridWrapper")), depth0))
    + "\">\n"
    + ((stack1 = container.invokePartial(require("./atm-grid.handlebars"),depth0,{"name":"atm-grid","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n      </div>\n      <button class=\"btn btn-success _copy-emails mx-auto my-5 d-block\">Copy Emails to Clipboard</button>\n    </div>\n    <!-- end section-main -->\n    <div class=\"_fg-attendee-master-section-detail "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"publicContainer")), depth0))
    + " mt-5\">\n      <div id=\"_fg-attendee-detail\" class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"publicContent")), depth0))
    + "\"></div>\n      <div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"publicContent")), depth0))
    + "\">"
    + ((stack1 = container.invokePartial(require("../partials/dropzone.handlebars"),depth0,{"name":"dropzone","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n    </div>\n    <!-- end section detail -->\n  </div>\n</section>\n"
    + ((stack1 = container.invokePartial(require("../partials/scroll-to-top.handlebars"),depth0,{"name":"scroll-to-top","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});