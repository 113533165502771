var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"flexGapColumn2")), depth0))
    + "\">\n      <textarea id=\"_chat-input-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"r_id") : depth0), depth0))
    + "\"\n                name=\"wpcf-note\"\n                class=\"form-control p-3 handle-input\"\n                placeholder=\"Send DM to "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,__default(require("../helpers/utils.js")).call(alias3,"admin_logged_in",{"name":"utils","hash":{},"data":data,"loc":{"start":{"line":8,"column":46},"end":{"line":8,"column":71}}}),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":8,"column":40},"end":{"line":8,"column":102}}})) != null ? stack1 : "")
    + "\"\n                data-target=\"#submitChatOnEnter-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"r_id") : depth0), depth0))
    + "\"\n                data-module=\"handle_noop\"\n                onfocus=\"$('#_enterWillSend-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"r_id") : depth0), depth0))
    + "').addClass('animated fadeOut')\"\n                rows=\"4\"></textarea>\n      <i class=\"fas fa-circle-notch fa-spin d-none\"></i>\n    </span>\n      <button id=\"submitChatOnEnter-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"r_id") : depth0), depth0))
    + "\"\n              class=\"handle-click mt-3 btn btn-legiblue\"\n              style=\"align-items: center\"\n              data-module=\"handle_submit_host_chat_rsvp\"\n              data-meta-key=\"wpcf-note\">Send</button>\n  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "attendee";
},"4":function(container,depth0,helpers,partials,data) {
    return "Parker";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../helpers/log.js")).call(alias1,"~~~~~~~ rendering crud-chat-host",depth0,{"name":"log","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":47}}}))
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"rsvp_notes") : stack1)) != null ? lookupProperty(stack1,"meta") : stack1),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":21,"column":9}}})) != null ? stack1 : "");
},"useData":true});