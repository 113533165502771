import Globals from '@lib/globals';

export default function(rating, index) {
  var cl = [];
  rating = rating || 0;
  if (index == 0) {
    cl.push('fas');
    cl.push('fa-ban');
    cl.push('text-danger');
  } else {
    if (rating >= index) {
      cl.push('fas');
    } else if (rating < index) {
      cl.push('far');
    }
    cl.push('fa-star');
  }
  return cl.join(' ');
}
