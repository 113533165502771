/**
 * https://stackoverflow.com/questions/8853396/logical-operator-in-a-handlebars-js-if-conditional
 */
 export default function ( v1, operator, v2, options ) {
   // {{#ifCond var1 '==' var2}}
   switch ( operator ) {
     case '==':
       return ( v1 == v2 ) ? options.fn( this ) : options.inverse( this );
     case '===':
       return ( v1 === v2 ) ? options.fn( this ) : options.inverse( this );
     case '!=':
       return ( v1 != v2 ) ? options.fn( this ) : options.inverse( this );
     case '!==':
       return ( v1 !== v2 ) ? options.fn( this ) : options.inverse( this );
     case '<':
       return ( v1 < v2 ) ? options.fn( this ) : options.inverse( this );
     case '<=':
       return ( v1 <= v2 ) ? options.fn( this ) : options.inverse( this );
     case '>':
       return ( v1 > v2 ) ? options.fn( this ) : options.inverse( this );
     case '>=':
       return ( v1 >= v2 ) ? options.fn( this ) : options.inverse( this );
     case '&&':
       return ( v1 && v2 ) ? options.fn( this ) : options.inverse( this );
     case '||':
       return ( v1 || v2 ) ? options.fn( this ) : options.inverse( this );
     default:
       return options.inverse( this );
   }
 }
