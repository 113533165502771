var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"cursorPointer")), depth0))
    + " radio _radio-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n              <div class=\"check-wrapper\">\n                  <input id=\"_input-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n                         type=\"radio\"\n                         name=\""
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"radio_data") : depths[1])) != null ? lookupProperty(stack1,"meta_key") : stack1), depth0))
    + "\"\n                         value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\"\n                         data-module=\"handle_radio_submit\"\n                         data-parent=\"._wpt-form-set-radios-"
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"radio_data") : depths[1])) != null ? lookupProperty(stack1,"meta_key") : stack1), depth0))
    + "\"\n                         class=\"handle-click wpt-form-radio radio form-radio "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":77},"end":{"line":29,"column":113}}})) != null ? stack1 : "")
    + "\"\n                         "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":25},"end":{"line":30,"column":70}}})) != null ? stack1 : "")
    + "/>\n                  <i class=\"fas fa-check _icon "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"icon")), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"redShadow")), depth0))
    + "\"></i>\n              </div>\n              <label for=\"_input-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "</label>\n            </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "selected";
},"4":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"radio_data") : depth0)) != null ? lookupProperty(stack1,"footnote") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":4},"end":{"line":42,"column":11}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./form-footnote.handlebars"),depth0,{"name":"form-footnote","hash":{"footnote":((stack1 = (depth0 != null ? lookupProperty(depth0,"radio_data") : depth0)) != null ? lookupProperty(stack1,"footnote") : stack1)},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"editable")), depth0))
    + " _fg-editable _input-group-"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"radio_data") : depth0)) != null ? lookupProperty(stack1,"meta_key") : stack1), depth0))
    + "\">\n  <label class=\"prompt-label\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "</label>\n  <div class=\"form-group\" data-action=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"action") : depth0), depth0))
    + "\">\n    <div>\n      <ul class=\"wpt-form-set-radios _wpt-form-set-radios-"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"radio_data") : depth0)) != null ? lookupProperty(stack1,"meta_key") : stack1), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"radio_data") : depth0)) != null ? lookupProperty(stack1,"li") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":35,"column":17}}})) != null ? stack1 : "")
    + "      </ul>\n    </div>\n  </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,__default(require("../helpers/utils.js")).call(alias3,"admin_logged_in",{"name":"utils","hash":{},"data":data,"loc":{"start":{"line":39,"column":12},"end":{"line":39,"column":37}}}),{"name":"unless","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":2},"end":{"line":43,"column":13}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});