var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias1(__default(require("../helpers/log.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"~~~~~~~ rendering atm-grid-detail-crud",depth0,{"name":"log","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":53}}}))
    + "\n<div class=\""
    + alias1(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fgAMCrud")), depth0))
    + "\">\n"
    + ((stack1 = container.invokePartial(require("../partials/crud-user-profile.handlebars"),depth0,{"name":"crud-user-profile","hash":{"response":(depth0 != null ? lookupProperty(depth0,"response") : depth0)},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  <div id=\"atmPartiesAttended\">\n"
    + ((stack1 = container.invokePartial(require("./atm-parties-attended.handlebars"),depth0,{"name":"atm-parties-attended","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n  <div data-admin=\"1\"\n       data-id=\""
    + alias1(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"a_id") : stack1), depth0))
    + "\"\n       id=\"_profile-images\"\n       class=\"_fg-secondary-images-public\">\n"
    + ((stack1 = container.invokePartial(require("../partials/image-management.handlebars"),depth0,{"name":"image-management","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n  <div id=\"_love-connection-"
    + alias1(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"a_id") : stack1), depth0))
    + "\"\n       class=\""
    + alias1(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"loveConnections")), depth0))
    + " my-4\">\n  </div>\n  <div id=\"_analytics-history-"
    + alias1(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"a_id") : stack1), depth0))
    + "\"\n       class=\""
    + alias1(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"analyticsHistory")), depth0))
    + "\"></div>\n  <div id=\"_score-breakdown-"
    + alias1(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"a_id") : stack1), depth0))
    + "\"\n       class=\"\"></div>\n</div> <!-- End Attendee Detail -->";
},"usePartial":true,"useData":true});