var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"mt-3 h5\">PS: Show this to our door guy to let him know you are ("
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"rsvp_count") : depth0), depth0))
    + ") guys</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"h3\">\n  All set, "
    + container.escapeExpression(__default(require("../helpers/defaultVal.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"first_name") : depth0),"man",{"name":"defaultVal","hash":{},"data":data,"loc":{"start":{"line":2,"column":11},"end":{"line":2,"column":42}}}))
    + ", now get naked.\n  <div class=\"mt-3\"\n       style=\"font-size: 150%;\">&#128520;</div>\n"
    + ((stack1 = __default(require("../helpers/ifCond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"rsvp_count") : depth0),">",1,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":2},"end":{"line":7,"column":13}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});