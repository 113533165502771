var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <li>\n            <label for=\"position-radio-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n              <input type=\"radio\"\n                     id=\"position-radio-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n                     name=\"wpcf-position\"\n                     class=\"wpcf-position\"\n                     value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"form") : depth0), depth0))
    + "\"\n                     data-value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"str") : depth0), depth0))
    + "</label>\n          </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <option value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias2(__default(require("../helpers/log.js")).call(alias1,"~~~~~~~ rendering launch-panel-welcome-email",depth0,{"name":"log","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":59}}}))
    + "\n\n<div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"welcome")), depth0))
    + " pb-5 mb-5\">\n  <div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"formGrid")), depth0))
    + "\">\n    <input type=\"email\"\n           name=\"wpcf-email\"\n           class=\"wpcf-email form-control\"\n           value=\"\"\n           aria-required=\"true\"\n           aria-invalid=\"false\"\n           placeholder=\"Email (required)\">\n  </div>\n  <div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"formGrid")), depth0))
    + "\">\n    <input type=\"text\"\n           name=\"wpcf-first-name-private\"\n           class=\"wpcf-first-name-private form-control\"\n           value=\"\"\n           aria-required=\"true\"\n           aria-invalid=\"false\"\n           placeholder=\"Name\">\n  </div>\n  <div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"formGrid")), depth0))
    + "\">\n    <input type=\"text\"\n           name=\"wpcf-age\"\n           class=\"wpcf-age form-control\"\n           value=\"\"\n           aria-required=\"true\"\n           aria-invalid=\"false\"\n           placeholder=\"Age\">\n  </div>\n  <div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"formGrid")), depth0))
    + "\">\n    <input type=\"text\"\n           name=\"wpcf-location\"\n           class=\"wpcf-location form-control\"\n           value=\"\"\n           aria-required=\"true\"\n           aria-invalid=\"false\"\n           placeholder=\"Location\">\n  </div>\n  <div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"formGrid")), depth0))
    + "\">\n    <select name=\"post-status\"\n            class=\"post-status form-control\">\n      <option value=\"pending\">Pending</option>\n      <option value=\"publish\">Publish</option>\n    </select>\n  </div>\n  <div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"formGrid")), depth0))
    + "\">\n    <div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"center")), depth0))
    + "\">\n      <input id=\"check-reminder\"\n             type=\"checkbox\"\n             name=\"reminder\"\n             style=\"\n                -ms-transform: scale(2);\n                -moz-transform: scale(2);\n                -webkit-transform: scale(2);\n                -o-transform: scale(2);\n                transform: scale(2);\n                padding: 10px;\"\n             class=\"_fg-welcome-reminder\">\n      <label for=\"check-reminder\"\n             class=\"mb-0 pt-0\">Reminder?</label>\n    </div>\n  </div>\n\n  <div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"formGrid")), depth0))
    + " "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"formFull")), depth0))
    + "\">\n    <div>\n      <ul style=\"list-style-type: none\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,__default(require("../helpers/values.js")).call(alias1,__default(require("../helpers/globals.js")).call(alias1,"POSITION",{"name":"globals","hash":{},"data":data,"loc":{"start":{"line":68,"column":25},"end":{"line":68,"column":46}}}),{"name":"values","hash":{},"data":data,"loc":{"start":{"line":68,"column":16},"end":{"line":68,"column":48}}}),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":8},"end":{"line":79,"column":17}}})) != null ? stack1 : "")
    + "      </ul>\n    </div>\n  </div>\n  <div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"formGrid")), depth0))
    + "\">\n    <input type=\"text\"\n           name=\"wpcf-attendee-note\"\n           class=\"wpcf-attendee-note form-control\"\n           value=\"Twitter "
    + alias2(__default(require("../helpers/dateFormat.js")).call(alias1,"today","MM/DD/YYYY",{"name":"dateFormat","hash":{},"data":data,"loc":{"start":{"line":87,"column":26},"end":{"line":87,"column":61}}}))
    + "\"\n           aria-required=\"true\"\n           aria-invalid=\"false\">\n  </div>\n  <div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"formGrid")), depth0))
    + "\">\n    <select name=\"wpcf-contact-type\"\n            class=\"wpcf-contact-type form-control\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,__default(require("../helpers/globals.js")).call(alias1,"CONTACT_TYPES",{"name":"globals","hash":{},"data":data,"loc":{"start":{"line":94,"column":14},"end":{"line":94,"column":39}}}),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":94,"column":6},"end":{"line":96,"column":15}}})) != null ? stack1 : "")
    + "    </select>\n  </div>\n  <div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"formGrid")), depth0))
    + "\">\n    <input type=\"text\"\n           name=\"wpcf-contact-value\"\n           class=\"wpcf-contact-value form-control\"\n           placeholder=\"Contact Handle\">\n  </div>\n  <div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"formGrid")), depth0))
    + " "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"formFull")), depth0))
    + "\">\n    <textarea style=\"width: 100%\"\n              name=\"message\"\n              class=\"_fg-body form-control\"\n              rows=\"25\"\n              aria-required=\"true\"\n              aria-invalid=\"false\">\n"
    + ((stack1 = container.invokePartial(require("./welcome-copy.handlebars"),depth0,{"name":"welcome-copy","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </textarea>\n  </div>\n  <div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"formGrid")), depth0))
    + " "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"formFull")), depth0))
    + "\">\n    <button class=\"handle-click btn btn-success btn-lg btn-half-block my-5 form-control\"\n            data-module=\"handle_launch_send_welcome_email\">Send</button>\n  </div>\n</div>";
},"usePartial":true,"useData":true});