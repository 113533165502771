var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n  <h3>Enter your email</h3>\n</div>\n<div><input type=\"email\"\n         autocapitalize=\"none\"\n         class=\"form-control form-control-lg text-center "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"mono")), depth0))
    + "\"\n         id=\"checkin-code\"\n         name=\"email\"></div>\n"
    + ((stack1 = container.invokePartial(require("./tryAgain.handlebars"),depth0,{"name":"tryAgain","hash":{"again":false,"pulse":true},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});