var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fgSuperscript")), depth0))
    + " text-success\">Private</span>";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-contact-id=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"contact_id") : depth0), depth0))
    + "\"";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-term=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"term") : depth0), depth0))
    + "\"";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"classes") : depth0), depth0))
    + " ";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"footnote") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":4},"end":{"line":27,"column":11}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <span class=\"mt-4\">"
    + ((stack1 = container.invokePartial(require("./form-footnote.handlebars"),depth0,{"name":"form-footnote","hash":{"footnote":(depth0 != null ? lookupProperty(depth0,"footnote") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias2(__default(require("../helpers/log.js")).call(alias1,"~~~~~~~ rendering crud-single-input",depth0,{"name":"log","hash":{},"data":data,"loc":{"start":{"line":12,"column":0},"end":{"line":12,"column":50}}}))
    + "\n\n<div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"editable")), depth0))
    + " _fg-editable _input-group-"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"meta_key") : depth0), depth0))
    + "\">\n  <label class=\"prompt-label\">"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + ":"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"private") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":40},"end":{"line":15,"column":134}}})) != null ? stack1 : "")
    + "</label>\n  <div data-meta-key=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"meta_key") : depth0), depth0))
    + "\"\n      "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"contact_id") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":6},"end":{"line":17,"column":63}}})) != null ? stack1 : "")
    + "\n      "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"term") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":6},"end":{"line":18,"column":45}}})) != null ? stack1 : "")
    + "\n       data-action=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"action") : depth0), depth0))
    + "\"\n       data-placeholder=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"placeholder") : depth0), depth0))
    + "\"\n       data-module=\"handle_inline_click\"\n       class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"classes") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":14},"end":{"line":22,"column":48}}})) != null ? stack1 : "")
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"editInline")), depth0))
    + " "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"flexGap2")), depth0))
    + " _fg-edit-inline handle-click\">\n  </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,__default(require("../helpers/utils.js")).call(alias1,"admin_logged_in",{"name":"utils","hash":{},"data":data,"loc":{"start":{"line":24,"column":12},"end":{"line":24,"column":37}}}),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":2},"end":{"line":28,"column":13}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});