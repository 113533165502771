var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Footer -->\n<div>\n  <div class=\"_fg-public-footer "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"publicFooter")), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"center")), depth0))
    + "\">\n    <div class=\"logout btn btn-sm btn-outline-danger mb-5 handle-click\" data-module=\"handle_user_logout\">Logout</div>\n    <div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"flexGap3")), depth0))
    + "\">\n      <a target=\"_blank\" href=\"https://twitter.com/wantgroupm4m\" class=\"p-3 btn btn-link color-twitter\" rel=\"noopener noreferrer\"><i class=\"fab fa-twitter fa-2x\"></i></a>\n      <a target=\"_blank\" href=\"https://www.snapchat.com/add/wantgroup?share_id=Qjg2RjNC&locale=en_US\" class=\"p-3 btn btn-link color-snapchat\" rel=\"noopener noreferrer\"><i class=\"fab fa-snapchat-ghost fa-2x\"></i></a>\n    </div>\n  </div>\n</div>\n";
},"useData":true});