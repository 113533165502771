var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias1(__default(require("../helpers/log.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"~~~~~~~ rendering crud-user-profile-admin-extras",depth0,{"name":"log","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":63}}}))
    + "\n\n<div id=\"atmContacts\" class=\"my-4\"\n     data-attendee-id=\""
    + alias1(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"response"))) && lookupProperty(stack1,"a_id")), depth0))
    + "\">\n"
    + ((stack1 = container.invokePartial(require("./crud-contacts.handlebars"),depth0,{"name":"crud-contacts","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n<div>\n    <pre id=\"fg-sg-events-"
    + alias1(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"response"))) && lookupProperty(stack1,"a_id")), depth0))
    + "\"\n         class=\""
    + alias1(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"pre6")), depth0))
    + " invisible mt-4\"></pre>\n</div>\n";
},"usePartial":true,"useData":true});