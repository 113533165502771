import { admin_logged_in } from '@lib/shared/util';
import Globals from '@lib/globals';
import home_url from '@template/helpers/home_url';

export default function(meta, image, admin) {
  const user_buttons = ['remove', 'hide', 'primary'],
    admin_buttons = ['face', 'featured', 'edit', 'approve'],
    is = Globals.IMAGE_STATUS;

  let buttons = admin ? admin_buttons : user_buttons;

  let data = [];

  buttons.forEach(button => {
    const status = image.image_status,
          hide_deleted = status == is.removed.int ? " d-none" : "";
    switch (button) {
      case 'primary':
        // Add two and just easier to toggle later
        data.push({
          name: `<i class="far text-secondary fa-star _icon"></i><span class="${Globals.STYLES.buttonHint}">Primary</span>`,
          type: 'span',
          atts: (function() {
            var cl = `handle-click fa-fw _make_primary ${Globals.STYLES.mini}`;
            var exclude_when_hidden = !meta.allow_face && image.face_showing;
            if (exclude_when_hidden || status != is.approved.int || image.is_primary) {
              cl += ' d-none';
            }
            return {
              class: cl,
              'data-module': 'handle_user_make_image_primary',
              title: 'Make Primary Image'
            };
          })()
        });
        data.push({
          name: `<i class="fas text-success fa-star _icon"></i><span class="${Globals.STYLES.buttonHint}">Primary</span>`,
          type: 'span',
          atts: (function() {
            var cl = `text-success fa-fw ${Globals.STYLES.mini}`;
            if (!image.is_primary) {
              cl += ' d-none';
            }
            return {
              class: cl,
              title: 'This is your Primary Image'
            };
          })()
        });
        break;
      case 'remove':
        data.push({
          name: `<i class="fas text-danger fa-times _icon"></i><span class="${Globals.STYLES.buttonHint}">Delete</span>`,
          type: 'span',
          atts: (function() {
            return {
              class: `handle-click fa-fw ${Globals.STYLES.mini}${hide_deleted}`,
              'data-module': 'handle_user_remove_image_click',
              title: 'Delete'
            };
          })()
        });
        break;
      case 'hide':
        data.push({
          name:
            status == is.hidden.int
              ? `<i class="fas text-info fa-eye _icon"></i><span class="${Globals.STYLES.buttonHint}">Un-Hide</span>`
              : `<i class="far text-secondary fa-flip-horizontal fa-eye-slash _icon"></i><span class="${Globals.STYLES.buttonHint}">Hide</span>`,
          type: 'span',
          atts: (function() {
            let cl = '';
            let exclude_hide = !meta.allow_face && image.face_showing;
            if (
              exclude_hide ||
              status == is.removed.int ||
              status == is.review.int ||
              image.is_primary
            ) {
              cl = 'd-none';
            } else if (status == is.hidden.int) {
              cl = 'handle-click';
            } else {
              cl = 'handle-click';
            }
            return {
              class: `${cl} fa-fw ${Globals.STYLES.mini}`,
              'data-module': 'handle_user_hide_image_click',
              title: status == is.hidden.int ? 'Un-hide' : 'Hide'
            };
          })()
        });
        break;
      case 'approve':
        data.push({
          name: Object.entries(is).filter(arr => arr[1].int == status)[0][1].str,
          type: 'button',
          atts: (function() {
            let cl = '';
            switch ( parseInt( status) )  {
              case is.removed.int:
                cl = 'd-none';
                break;
              case is.approved.int:
                cl = 'btn btn-sm btn-success handle-click';
                break;
              case is.review.int:
                cl = 'btn btn-sm btn-warning handle-click';
                break;
              default:
                cl = 'd-none';
                break;
            }
            return {
              class: cl,
              'data-module': 'handle_launch_approve_image'
            };
          })()
        });
        break;
      case 'featured':
        data.push({
          name: 'Make Featured',
          type: 'button',
          atts: (function() {
            return {
              class: `btn btn-sm btn-default _make-featured handle-click${hide_deleted}`,
              'data-module': 'handle_make_featured_image'
            };
          })()
        });
        break;
      case 'face':
        data.push({
          name: image.face_showing ? 'Face Showing' : 'Face Hidden',
          type: 'button',
          atts: (function() {
            return {
              class: `btn btn-default btn-sm handle-click${hide_deleted}`,
              'data-module': 'handle_launch_toggle_face_image'
            };
          })()
        });
        break;
      case 'edit':
        data.push({
          name: 'Edit',
          type: 'a',
          atts: (function() {
            return {
              href: home_url('/wp-admin/post.php?post=' + image.thumbnail_id + '&action=edit'),
              class: `btn btn-sm btn-outline-secondary${hide_deleted}`,
              rel: 'noopener noreferrer'
            };
          })()
        });
        break;
    }
  });

  return data;
}
