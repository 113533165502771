var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fgThumb")), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"gridBrick")), depth0))
    + "\">\n      <div class=\"handle-click\" data-module=\"handle_load_admin_grid_detail\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"picture_data") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":8,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("../partials/ratings.handlebars"),depth0,{"name":"ratings","hash":{"hover":true,"a_id":(depth0 != null ? lookupProperty(depth0,"a_id") : depth0),"rating":(depth0 != null ? lookupProperty(depth0,"rating") : depth0)},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        <div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"scoreBadgeHover")), depth0))
    + "\">"
    + ((stack1 = container.invokePartial(require("../partials/score-badge.handlebars"),depth0,{"name":"score-badge","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>        \n      </div>\n      <span class=\"_fg-modal-on-demand\" data-admin=\"1\" data-attendee-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"a_id") : depth0), depth0))
    + "\" data-post-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"a_id") : depth0), depth0))
    + "\"></span>\n      "
    + ((stack1 = __default(require("../partials/atmOverlays.js")).call(alias3,depth0,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles")),{"name":"atmOverlays","hash":{},"data":data,"loc":{"start":{"line":13,"column":6},"end":{"line":13,"column":41}}})) != null ? stack1 : "")
    + "\n    </div>\n    <div>\n      <div data-toggle=\"tooltip\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "\" data-clipboard-text=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "\" class=\"_fg-copy-click text-accent-color mb-3\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "<i class=\"far fa-copy ml-2 text-muted\"></i></div>\n      <div>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"date") : depth0), depth0))
    + "</div>\n    </div>\n    <div>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"email_contents") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":20,"column":6},"end":{"line":24,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"sms_contents") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":6},"end":{"line":27,"column":14}}})) != null ? stack1 : "")
    + "</div>\n    <div>\n      <div class=\"mb-3\" data-toggle=\"tooltip\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"user_agent") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"event_count") : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"event_name") : depth0), depth0))
    + "s</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,__default(require("../helpers/regex.js")).call(alias3,"(click|open)",(depth0 != null ? lookupProperty(depth0,"event_name") : depth0),{"name":"regex","hash":{},"data":data,"loc":{"start":{"line":31,"column":12},"end":{"line":31,"column":46}}}),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":6},"end":{"line":35,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n    <div>\n      <div data-toggle=\"tooltip\" data-html=\"true\" title=\""
    + alias2(__default(require("../helpers/implode.js")).call(alias3,", ",__default(require("../helpers/values.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"geo") : depth0),{"name":"values","hash":{},"data":data,"loc":{"start":{"line":38,"column":72},"end":{"line":38,"column":84}}}),{"name":"implode","hash":{},"data":data,"loc":{"start":{"line":38,"column":57},"end":{"line":38,"column":86}}}))
    + "\">"
    + alias2(__default(require("../helpers/implode.js")).call(alias3,", ",__default(require("../helpers/uniqueArray.js")).call(alias3,__default(require("../helpers/explode.js")).call(alias3,", ",__default(require("../helpers/implode.js")).call(alias3,", ",__default(require("../helpers/values.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"geo") : depth0),{"name":"values","hash":{},"data":data,"loc":{"start":{"line":38,"column":144},"end":{"line":38,"column":156}}}),{"name":"implode","hash":{},"data":data,"loc":{"start":{"line":38,"column":130},"end":{"line":38,"column":157}}}),{"name":"explode","hash":{},"data":data,"loc":{"start":{"line":38,"column":116},"end":{"line":38,"column":158}}}),{"name":"uniqueArray","hash":{},"data":data,"loc":{"start":{"line":38,"column":103},"end":{"line":38,"column":159}}}),{"name":"implode","hash":{},"data":data,"loc":{"start":{"line":38,"column":88},"end":{"line":38,"column":162}}}))
    + "</div>\n    </div>\n    <div data-toggle=\"tooltip\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\">"
    + alias2(__default(require("../helpers/truncate.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"url") : depth0),40,{"name":"truncate","hash":{},"data":data,"loc":{"start":{"line":40,"column":47},"end":{"line":40,"column":66}}}))
    + "</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../partials/picture.handlebars"),depth0,{"name":"picture","hash":{"grid":(depth0 != null ? lookupProperty(depth0,"grid") : depth0)},"data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button class=\"btn btn-sm btn-legiblue handle-click\" data-module=\"handle_sg_log_message_load\" data-extension=\".html\" data-toggle=\"tooltip\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"which_notification") : depth0), depth0))
    + "\" data-message-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"message_id") : depth0), depth0))
    + "\"><i class=\"far fa-envelope\"></i></button>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"which_notification") : depth0), depth0))
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button class=\"btn btn-sm btn-legiblue fg-message-source ml-2 handle-click\" data-module=\"handle_sg_log_message_load\" data-extension=\".txt\" data-toggle=\"tooltip\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"which_notification") : depth0), depth0))
    + "\" data-message-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"message_id") : depth0), depth0))
    + "\"><i class=\"fas fa-sms\"></i></button>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"event_count_history") : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":8},"end":{"line":34,"column":17}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    return "          <div>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias2(__default(require("../helpers/log.js")).call(alias1,"~~~~~~~ rendering launch-panel-sglog",depth0,{"name":"log","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":51}}}))
    + "\n<div class=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"sgLogGrid")), depth0))
    + " "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fullWidthSm")), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"sg_log") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":41,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("../partials/load-more.handlebars"),depth0,{"name":"load-more","hash":{"target":"#_launch-sg-log","key":"timestamp","data":((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"sg_log") : stack1)},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});