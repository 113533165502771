var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n  <label class=\"prompt-label\">Contacts:</label>\n\n  <div class=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"contactsGrid")), depth0))
    + " py-3\">\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"contacts") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":17,"column":13}}})) != null ? stack1 : "")
    + "\n    <div><span class=\"text-success handle-click\"\n            data-module=\"handle_contacts\"\n            data-action=\"add\"\n            data-target=\"._add-contact\"><i class=\"fas fa-plus fa-fw\"></i></span></div>\n    <div class=\"_add-contact invisible\">\n      <select name=\"wpcf-contact-type\"\n              class=\"wpcf-contact-type form-control-sm\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,__default(require("../helpers/globals.js")).call(alias1,"CONTACT_TYPES",{"name":"globals","hash":{},"data":data,"loc":{"start":{"line":26,"column":16},"end":{"line":26,"column":41}}}),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":8},"end":{"line":28,"column":17}}})) != null ? stack1 : "")
    + "      </select>\n    </div>\n    <div class=\"_add-contact invisible\">\n      <input type=\"text\"\n             name=\"wpcf-contact-value\"\n             class=\"wpcf-contact-value form-control-sm\"\n             placeholder=\"Contact Handle\">\n    </div>\n    <div class=\"_add-contact invisible\">\n      <span class=\"handle-click\"\n            data-module=\"handle_contacts\"\n            data-action=\"save\"><i class=\"fas fa-check fa-fw text-success\"></i>\n    </div>\n  </div>\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div><span class=\"text-danger handle-click\"\n              data-module=\"handle_contacts\"\n              data-contact-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"c_id") : depth0), depth0))
    + "\"\n              data-action=\"remove\"><i class=\"fas fa-minus fa-fw\"></i></span></div>\n      <div>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "</div>\n      <div>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "</div>\n      <div></div> \n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <option value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../helpers/log.js")).call(alias1,"~~~~~~~ rendering crud-contacts",depth0,{"name":"log","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":46}}}))
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"response"))) && lookupProperty(stack1,"user_profile")),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":44,"column":9}}})) != null ? stack1 : "");
},"useData":true});