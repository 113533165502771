export default function( bool ) {
  if ( null === bool ) return true;
  switch (typeof bool) {
    case 'undefined':
    case 'boolean':
      return ! bool;
      break;
    case 'string':
      return parseInt(bool) === 0;
      break;
    case 'object':
      return Array.isArray(bool) ? ! bool.length > 0 : ! Object.values(bool).length > 0;
      break;
  }
}