/* This is used to determine if the party is on ice, but also considers an attendee's status
   Returns true only if party is on ice or party is first class and user isn't
*/


export default function() {
  var p_id = this.response.p_id,
    active_party = this.response.active_parties[ p_id ],
    partyIsFirstClass = active_party.is_party_first_class,
    userIsFirstClass = active_party.is_first_class_user,
    partyIsOnIce = active_party.is_party_on_ice;
  return partyIsOnIce || ( partyIsFirstClass && !userIsFirstClass );
}
