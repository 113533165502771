import Globals from '@lib/globals';
import { admin_logged_in } from '@lib/shared/util';

export default function(attendee_data, field, options) {
  var styles = options.data.root.styles;
  var ban =
      attendee_data.allow_face == false && this.face_showing == true
        ? `_icon _icon-overlay ${styles.imageBan} far fa-eye-slash`
        : false,
    under_review =
      this.image_status == Globals.IMAGE_STATUS.review.int
        ? `_icon _icon-overlay ${styles.underReview} far fa-clock _fg-under-review`
        : false,
    deleted =
      this.image_status == Globals.IMAGE_STATUS.removed.int
        ? `_icon _icon-overlay ${styles.imageDeleted} fas fa-times _fg-deleted`
        : false,
    user_hidden =
      this.image_status == Globals.IMAGE_STATUS.hidden.int
        ? `_icon _icon-overlay ${styles.imageHidden} far fa-eye-slash  _fg-image-hidden`
        : false,
    icon = ['<i class="', '', '"></i>'],
    tooltip = '',
    blur_img = admin_logged_in() ? '' : styles.blur,
    icon_classes = false;

  if (under_review) {
    icon_classes = icon[1] = under_review;
    tooltip = 'Your image should be approved soon';
  } else if (deleted) {
    icon_classes = icon[1] = deleted;
    tooltip = 'Image has been flagged for removal';
  } else if (ban) {
    icon_classes = icon[1] = ban;
    tooltip = 'Image hidden because you selected "No" for allowing face pics';
  } else if (user_hidden) {
    icon_classes = icon[1] = user_hidden;
    tooltip = 'Image hidden because you marked it as hidden';
  }

  let return_data = {
    overlay: icon.join(''),
    thumbTip: tooltip ? `<span class="${styles.thumbTip} _thumb-tip">${tooltip}</span>` : '',
    tooltip: { title: tooltip, "data-toggle": "tooltip" },
    blur: blur_img
  };

  if (!field) {
    return return_data;
  }

  return return_data[field] || '';
}
