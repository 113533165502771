import { get_party_meta } from '@lib/shared/util';
import Globals from '@lib/globals';

export default function(options) {
  var response = options.data.root.response,
    party_meta = get_party_meta(response.p_id),
    affirmative_replies = [Globals.REPLY.fresh_yes.int, Globals.REPLY.firm_yes.int, Globals.REPLY.maybe.int];

  // Is it a number?
  if (isNaN(party_meta.room)) {
    return false;
  }

  // Proper reply?
  if (!affirmative_replies.includes(response.user_rsvp.reply_n)) {
    return false;
  }

  // Party inactive?
  if (party_meta.is_active !== Globals.PARTY_STATUS.active.int) {
    return false;
  }

  return true;
}
