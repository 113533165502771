import Globals from '@lib/globals';

/**
 *   Returns the grid or enlarge data for a given featured image, filtered on a_id
 *   ready to pass into "picture" partial
 */
export default function( a_id, usage ) {
  usage = usage || "grid";
  var rec = Globals.FEATURED_IMAGES.find( a => a.a_id == a_id );
  if ( rec ) {
    return rec[usage];
  }
  return `<img src="${ajax_obj.missing_img}">`;
}