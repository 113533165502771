export default function(data) {
  var response = data.data.root.response,
    styles = data.data.root.styles;
  if (response.err_null_code) {
    return '';
  }
  if (response.error || response.err_already_checked_in || response.err_unknown_a_id) {
    return styles.checkinFailure;
  }
  if (response.err_reply_invalid) {
    return styles.checkinWarning;
  }
  return styles.checkinSuccess;
}
