var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias1(__default(require("../helpers/log.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"~~~~~~~ rendering sms",depth0,{"name":"log","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":36}}}))
    + "\n\n<div id=\"_fg-text-opt-in\" class=\"modal fade "
    + alias1(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"smsOptIn")), depth0))
    + "\" tabindex=\"-1\">\n    <div class=\"modal-dialog\">\n        <div class=\"modal-content\">\n            <div class=\"modal-header\">\n                <h5 class=\""
    + alias1(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"smsHeader")), depth0))
    + " modal-title\">Want to receive SMS updates?</h5>\n            </div>\n            <div class=\"modal-body\">\n                <i class=\"fas fa-mobile-alt "
    + alias1(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"icon")), depth0))
    + " "
    + alias1(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"redShadow")), depth0))
    + " my-3\"></i>\n                <div class=\"fg-modal-body p-1\">\n                  <div class=\"input-group\">\n                    <div class=\"input-group-prepend\">\n                      <span class=\"input-group-text\" id=\"basic-addon1\">+1</span>\n                    </div>\n                    <input\n                      onkeyup=\"formatPhone(this);\"\n                      type=\"tel\"\n                      class=\"_fg-sms form-control\"\n                      data-attendee-id=\"\"\n                      data-contact-id=\"\"\n                      aria-label=\"SMS\"\n                      aria-describedby=\"basic-addon1\">\n                  </div>\n                  <div class=\"pt-3\">Emails often bypass your inbox. Enter your number if you would also like to receive text notifications for party invites, room updates and reset links.</div>\n                  <label class=\""
    + alias1(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fgAlert_danger")), depth0))
    + " _fg-alert d-none\"></label>\n                </div>\n            </div>\n            <div class=\""
    + alias1(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"smsFooter")), depth0))
    + " modal-footer\">\n                <span class=\"btn btn-success _fg-save-sms handle-click "
    + alias1(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"cursor-pointer")), depth0))
    + " "
    + alias1(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"smsSaveYes")), depth0))
    + "\" data-module=\"handle_user_sms_opt_in\">Yes, receive alerts</span>\n                <span class=\"btn btn-danger handle-click _fg-sms-stop-asking\" data-module=\"handle_user_sms_opt_in\">No, don't ask again</span>\n                <button class=\"btn btn-outline-dark _fg-sms-dismiss\" data-dismiss=\"modal\" aria-label=\"Close\">No thanks</button>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});