var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + alias2(alias1((data && lookupProperty(data,"key")), depth0))
    + "=\""
    + alias2(alias1(depth0, depth0))
    + "\" ";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <source media=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"media") : depth0), depth0))
    + "\" type=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "\" srcset=\""
    + alias2(__default(require("../helpers/mapSrcset.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"srcset") : depth0),{"name":"mapSrcset","hash":{},"data":data,"loc":{"start":{"line":8,"column":64},"end":{"line":8,"column":89}}}))
    + "\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(container.lambda((data && lookupProperty(data,"key")), depth0))
    + "=\""
    + ((stack1 = __default(require("../helpers/ifCond.js")).call(alias1,(data && lookupProperty(data,"key")),"==","src",{"name":"ifCond","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":11,"column":63}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../helpers/ifCond.js")).call(alias1,(data && lookupProperty(data,"key")),"!=","src",{"name":"ifCond","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":12,"column":52}}})) != null ? stack1 : "")
    + "\" ";
},"6":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../helpers/upload_url.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"upload_url","hash":{},"data":data,"loc":{"start":{"line":11,"column":32},"end":{"line":11,"column":51}}}));
},"8":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda(depth0, depth0));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<picture"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,__default(require("../helpers/merge.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"grid") : depth0)) != null ? lookupProperty(stack1,"picture_atts") : stack1),(depth0 != null ? lookupProperty(depth0,"picture_atts") : depth0),{"name":"merge","hash":{},"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":6,"column":54}}}),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":86}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"grid") : depth0)) != null ? lookupProperty(stack1,"sources") : stack1),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":2},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "  <img"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,__default(require("../helpers/merge.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"grid") : depth0)) != null ? lookupProperty(stack1,"image_atts") : stack1),(depth0 != null ? lookupProperty(depth0,"image_atts") : depth0),{"name":"merge","hash":{},"data":data,"loc":{"start":{"line":10,"column":14},"end":{"line":10,"column":48}}}),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":6},"end":{"line":13,"column":13}}})) != null ? stack1 : "")
    + ">\n    </picture>\n<i class=\"fas fa-circle-notch text-success mr-2 mb-2 fa-spin _icon "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"gridBrickProgress")), depth0))
    + "\"></i>";
},"useData":true});