var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"obfusEmail")), depth0))
    + "\">\n        <span>\n          <span class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"firstLetter")), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"first_letter_user_name") : depth0), depth0))
    + "</span>\n          <span class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"hiddenUName")), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"all_but_first_letter_username") : depth0), depth0))
    + "</span>\n          <span class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"domain")), depth0))
    + "\">@"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"domain") : depth0), depth0))
    + "</span>\n          <div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"name")), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</div>\n        </span>\n      </div>\n      <div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"check")), depth0))
    + "\"><i class=\"far fa-square fa-2x\"></i></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias2(__default(require("../helpers/log.js")).call(alias1,"~~~~~~~ rendering party-guest-checklist",depth0,{"name":"log","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":54}}}))
    + "\n\n<style media=\"screen,print\">\n  body,\n  div,\n  h1,\n  h2,\n  h3,\n  ol,\n  svg,\n  ul {\n    width   : auto;\n    border  : 0;\n    margin  : 0;\n    padding : 0;\n    float   : none!important;\n    position: static;\n    overflow: visible;\n    color   : #000;\n  }\n\n  body {\n    background: #fff;\n  }\n\n  * {\n    overflow: visible;\n  }\n\n  @page {\n    margin: 1in 0;\n  }\n\n  .topLink {\n    display: none;\n  }  \n</style>\n\n<section>\n  <div class=\""
    + alias2(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"partyChecklist")), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"response")),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":4},"end":{"line":51,"column":13}}})) != null ? stack1 : "")
    + "  </div>\n</section>\n";
},"useData":true});