var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"publicForm")), depth0))
    + " text-center mb-4\">\n  <div>\n    You will receive a one-time link to set your password. If you already have a password, it will be wiped.\n  </div>\n</div>\n<div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"requestPassword")), depth0))
    + " _fg-access mb-4\" data-fragment=\"#profile\">\n  <input\n    type=\"email\"\n    name=\"sender-email\"\n    value=\"\"\n    autocapitalize=\"none\"\n    data-target=\"#submitEnterPassword\"\n    class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"mono")), depth0))
    + " form-control _fg-email-request-access handle-keydown check-version\"\n    aria-required=\"true\"\n    aria-invalid=\"false\"\n    placeholder=\"E-Mail\" />\n    <span id=\"submitEnterPassword\" class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"verifyEmailButton")), depth0))
    + " handle-click touch-pulse fa-3x "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"cursor-pointer")), depth0))
    + "\" data-module=\"handle_party_access\">\n      <i class=\"far fa-arrow-alt-circle-right\"></i>\n    </span>\n    <label class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"fgAlert_danger")), depth0))
    + " d-none _cookie-check\">Cookies must be enabled before requesting access</label>\n</div>\n<a href=\""
    + ((stack1 = __default(require("../helpers/home_url.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"/reset/?t=login",{"name":"home_url","hash":{},"data":data,"loc":{"start":{"line":22,"column":9},"end":{"line":22,"column":41}}})) != null ? stack1 : "")
    + "\" id=\"returnToLogin\" class=\"btn btn-link handle-click\" data-module=\"handle_navigate\" data-template=\"reset\">Have a password? Go to Login</a>\n";
},"useData":true});