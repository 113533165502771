var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"t") : depth0), depth0))
    + "</div>\n  <div>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"party") : depth0), depth0))
    + "</div>\n  <div>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"src") : depth0), depth0))
    + "</div>\n  <div style=\"white-space: normal\">\n    <div class=\"mb-3\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"c") : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"evt") : depth0), depth0))
    + "'s</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,__default(require("../helpers/regex.js")).call(alias3,"(click|open)",(depth0 != null ? lookupProperty(depth0,"evt") : depth0),{"name":"regex","hash":{},"data":data,"loc":{"start":{"line":9,"column":10},"end":{"line":9,"column":37}}}),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":13,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n  <div>"
    + ((stack1 = __default(require("../helpers/trim.js")).call(alias3,{"name":"trim","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":7},"end":{"line":15,"column":100}}})) != null ? stack1 : "")
    + "</div>\n  <div data-toggle=\"tooltip\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\">"
    + alias2(__default(require("../helpers/truncate.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"url") : depth0),40,{"name":"truncate","hash":{},"data":data,"loc":{"start":{"line":16,"column":45},"end":{"line":16,"column":64}}}))
    + "</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"event_count_history") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":6},"end":{"line":12,"column":15}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    return "        <div>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../helpers/implode.js")).call(alias1,", ",__default(require("../helpers/uniqueArray.js")).call(alias1,__default(require("../helpers/explode.js")).call(alias1,", ",__default(require("../helpers/implode.js")).call(alias1,", ",__default(require("../helpers/values.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"geo") : depth0),{"name":"values","hash":{},"data":data,"loc":{"start":{"line":15,"column":74},"end":{"line":15,"column":86}}}),{"name":"implode","hash":{},"data":data,"loc":{"start":{"line":15,"column":60},"end":{"line":15,"column":87}}}),{"name":"explode","hash":{},"data":data,"loc":{"start":{"line":15,"column":46},"end":{"line":15,"column":88}}}),{"name":"uniqueArray","hash":{},"data":data,"loc":{"start":{"line":15,"column":33},"end":{"line":15,"column":89}}}),{"name":"implode","hash":{},"data":data,"loc":{"start":{"line":15,"column":18},"end":{"line":15,"column":91}}}));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../helpers/log.js")).call(alias1,"~~~~~~~ rendering atm-sg-events",depth0,{"name":"log","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":46}}}))
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"sg_events") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":17,"column":9}}})) != null ? stack1 : "");
},"useData":true});