/**
 * This exists to simply access all the util funcs via handlebars helpers
 * Don't bother lazy loading - they're already loaded elsewhere and reused
 */
import * as util from '@lib/shared/util';
export default function( func ) {
  if ( !util[ func ] ) {
    throw new Error( 'Invalid helper ' + func );
  }
  let args = [ ...arguments ];
  args.shift();
  return util[ func ]( ...args );
}
