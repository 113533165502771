var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(alias1,__default(require("../helpers/find.js")).call(alias1,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"response"))) && lookupProperty(stack1,"picture_data")),"a_id",(depth0 != null ? lookupProperty(depth0,"event_value") : depth0),{"name":"find","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":65}}}),{"name":"with","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":7,"column":13}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./picture.handlebars"),depth0,{"name":"picture","hash":{"image_atts":__default(require("../helpers/object.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"object","hash":{"title":(depths[1] != null ? lookupProperty(depths[1],"timestamp") : depths[1]),"data-toggle":"tooltip","alt":(depth0 != null ? lookupProperty(depth0,"a_id") : depth0),"data-attendee-id":(depth0 != null ? lookupProperty(depth0,"a_id") : depth0),"height":"100","width":"100"},"data":data,"loc":{"start":{"line":6,"column":38},"end":{"line":6,"column":143}}}),"grid":(depth0 != null ? lookupProperty(depth0,"grid") : depth0)},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias2(__default(require("../helpers/log.js")).call(alias1,"~~~~~~~ rendering analytics-thumb-grid-viewed",depth0,{"name":"log","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":60}}}))
    + "\n\n<div class=\""
    + alias2(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"viewedWhom")), depth0))
    + " mb-3\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,__default(require("../helpers/filter.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"analytics") : stack1),"event_name","open_modal",{"name":"filter","hash":{},"data":data,"loc":{"start":{"line":4,"column":10},"end":{"line":4,"column":63}}}),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":8,"column":11}}})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true,"useDepths":true});