import Globals from '@lib/globals';
/**
 *   Looks up the human xref for a given constant such as Reply, Notification Status, etc.
 */
export default function( type, intval ) {
  var match = Object.values( Globals[type] ).find( val => val.int == intval );
  if ( match ) {
    return match.str;
  }
  return 'Suspended';
}
