var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <div class=\"mb-5\">\n"
    + ((stack1 = container.invokePartial(require("../partials/party-select-dropdown.handlebars"),depth0,{"name":"party-select-dropdown","hash":{"p_id":__default(require("../helpers/utils.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"get_selected_party",{"name":"utils","hash":{},"data":data,"loc":{"start":{"line":5,"column":92},"end":{"line":5,"column":120}}}),"active_only":true,"selector":"_partySelectDropdownAnalytics"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias2(__default(require("../helpers/log.js")).call(alias1,"~~~~~~~ rendering launch-panel-analytics",depth0,{"name":"log","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":55}}}))
    + "\n\n"
    + ((stack1 = __default(require("../helpers/ifCond.js")).call(alias1,__default(require("../helpers/length.js")).call(alias1,__default(require("../helpers/filter.js")).call(alias1,__default(require("../helpers/values.js")).call(alias1,__default(require("../helpers/utils.js")).call(alias1,"get_party_meta",false,{"name":"utils","hash":{},"data":data,"loc":{"start":{"line":3,"column":34},"end":{"line":3,"column":64}}}),{"name":"values","hash":{},"data":data,"loc":{"start":{"line":3,"column":26},"end":{"line":3,"column":65}}}),"is_active",__default(require("../helpers/array.js")).call(alias1,1,3,{"name":"array","hash":{},"data":data,"loc":{"start":{"line":3,"column":78},"end":{"line":3,"column":89}}}),{"name":"filter","hash":{},"data":data,"loc":{"start":{"line":3,"column":18},"end":{"line":3,"column":92}}}),{"name":"length","hash":{},"data":data,"loc":{"start":{"line":3,"column":10},"end":{"line":3,"column":95}}}),">",1,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":7,"column":11}}})) != null ? stack1 : "")
    + "\n<div id=\"_analytics-grid\"\n     class=\""
    + alias2(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"analyticsGrid")), depth0))
    + "\">\n"
    + ((stack1 = container.invokePartial(require("../partials/analytics-console.handlebars"),depth0,{"name":"analytics-console","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});