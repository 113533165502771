var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"_fg-am-header "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"adminDetailHeader")), depth0))
    + "\">\n    <div>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"display_name") : depth0), depth0))
    + " [ "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"a_id") : depth0), depth0))
    + " ]</div>\n    <div><span class=\"text-accent-color mr-2\">Joined:</span>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"joined_on") : depth0), depth0))
    + "</div>\n    <div><span class=\"text-accent-color mr-2\">Last Login:</span>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"last_login") : depth0), depth0))
    + "</div>\n    <div>"
    + ((stack1 = container.invokePartial(require("../partials/score-badge.handlebars"),depth0,{"name":"score-badge","hash":{"linked":true,"large":true},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n    <span style=\"font-size: inherit\"\n          data-clipboard-text=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "\"\n          class=\"_fg-copy-click\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "<i class=\"far fa-copy ml-2 text-muted\"></i></span>\n    <div><button data-attendee-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"a_id") : depth0), depth0))
    + "\"\n              class=\"btn "
    + ((stack1 = __default(require("../helpers/ifCond.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"post_status") : depth0),"==","pending",{"name":"ifCond","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":25},"end":{"line":12,"column":84}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../helpers/ifCond.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"post_status") : depth0),"==","publish",{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":84},"end":{"line":12,"column":144}}})) != null ? stack1 : "")
    + " btn-sm handle-click "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"post_status") : depth0), depth0))
    + "\"\n              data-module=\"handle_publish_attendee\">"
    + ((stack1 = __default(require("../helpers/ifCond.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"post_status") : depth0),"==","pending",{"name":"ifCond","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":52},"end":{"line":13,"column":108}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../helpers/ifCond.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"post_status") : depth0),"==","publish",{"name":"ifCond","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":108},"end":{"line":13,"column":166}}})) != null ? stack1 : "")
    + "</button>\n    </div>\n    <div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"atmBackToGrid")), depth0))
    + "\">\n      <a class=\"_fg-back-to-grid handle-click btn btn-link btn-lg\"\n         data-module=\"handle_attendee_master_back_to_grid\"\n         style=\"font-size: 2rem\"\n         data-scroll=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"scroll") : depth0), depth0))
    + "\">\n        <i class=\"fas fa-times text-accent-color "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"redShadow")), depth0))
    + "\"></i>\n      </a>\n    </div>\n  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "btn-danger";
},"4":function(container,depth0,helpers,partials,data) {
    return "btn-success";
},"6":function(container,depth0,helpers,partials,data) {
    return "Pending";
},"8":function(container,depth0,helpers,partials,data) {
    return "Published";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../helpers/log.js")).call(alias1,"~~~~~~~ rendering atm-grid-detail-header",depth0,{"name":"log","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":55}}}))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"response") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":24,"column":9}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});