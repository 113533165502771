var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../helpers/ifCond.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"event_name") : depth0),"!=","open_modal",{"name":"ifCond","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":2},"end":{"line":25,"column":13}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <span class=\""
    + alias2(__default(require("../helpers/checkForPreBackground.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"event_name") : depth0),(depth0 != null ? lookupProperty(depth0,"event_value") : depth0),{"name":"checkForPreBackground","hash":{},"data":data,"loc":{"start":{"line":6,"column":17},"end":{"line":6,"column":65}}}))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":65},"end":{"line":6,"column":100}}})) != null ? stack1 : "")
    + "\">"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"timestamp") : depth0), depth0))
    + "</span>\n    <span class=\""
    + alias2(__default(require("../helpers/checkForPreBackground.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"event_name") : depth0),(depth0 != null ? lookupProperty(depth0,"event_value") : depth0),{"name":"checkForPreBackground","hash":{},"data":data,"loc":{"start":{"line":7,"column":17},"end":{"line":7,"column":66}}}))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":66},"end":{"line":7,"column":101}}})) != null ? stack1 : "")
    + "\">"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"event_name") : depth0), depth0))
    + "</span>\n"
    + ((stack1 = __default(require("../helpers/ifCond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"event_name") : depth0),"==","enlarge",{"name":"ifCond","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":16,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../helpers/ifCond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"event_name") : depth0),"!=","enlarge",{"name":"ifCond","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":4},"end":{"line":19,"column":15}}})) != null ? stack1 : "")
    + "    <span class=\""
    + alias2(__default(require("../helpers/checkForPreBackground.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"event_name") : depth0),(depth0 != null ? lookupProperty(depth0,"event_value") : depth0),{"name":"checkForPreBackground","hash":{},"data":data,"loc":{"start":{"line":20,"column":17},"end":{"line":20,"column":66}}}))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":66},"end":{"line":20,"column":101}}})) != null ? stack1 : "")
    + "\"\n          data-toggle=\"tooltip\"\n          title=\""
    + alias2(__default(require("../helpers/join.js")).call(alias1,", ",__default(require("../helpers/array.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"ip") : depth0),(depth0 != null ? lookupProperty(depth0,"city") : depth0),(depth0 != null ? lookupProperty(depth0,"state") : depth0),(depth0 != null ? lookupProperty(depth0,"region") : depth0),(depth0 != null ? lookupProperty(depth0,"country") : depth0),(depth0 != null ? lookupProperty(depth0,"continent") : depth0),{"name":"array","hash":{},"data":data,"loc":{"start":{"line":22,"column":29},"end":{"line":22,"column":75}}}),{"name":"join","hash":{},"data":data,"loc":{"start":{"line":22,"column":17},"end":{"line":22,"column":78}}}))
    + "\">"
    + alias2(__default(require("../helpers/defaultVal.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"address") : depth0),"Address N/A",{"name":"defaultVal","hash":{},"data":data,"loc":{"start":{"line":22,"column":80},"end":{"line":22,"column":116}}}))
    + "</span>\n    "
    + alias2(__default(require("../helpers/setVar.js")).call(alias1,"ua",__default(require("../helpers/utils.js")).call(alias1,"parseUserAgent",__default(require("../helpers/get.js")).call(alias1,"HTTP_USER_AGENT",(depth0 != null ? lookupProperty(depth0,"uri") : depth0),{"name":"get","hash":{},"data":data,"loc":{"start":{"line":23,"column":42},"end":{"line":23,"column":69}}}),{"name":"utils","hash":{},"data":data,"loc":{"start":{"line":23,"column":18},"end":{"line":23,"column":70}}}),{"name":"setVar","hash":{},"data":data,"loc":{"start":{"line":23,"column":4},"end":{"line":23,"column":73}}}))
    + "\n    <span class=\""
    + alias2(__default(require("../helpers/checkForPreBackground.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"event_name") : depth0),(depth0 != null ? lookupProperty(depth0,"event_value") : depth0),{"name":"checkForPreBackground","hash":{},"data":data,"loc":{"start":{"line":24,"column":17},"end":{"line":24,"column":66}}}))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":66},"end":{"line":24,"column":101}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"ua")),{"name":"with","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":103},"end":{"line":24,"column":202}}})) != null ? stack1 : "")
    + "</span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " text-danger";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <span><img src=\""
    + ((stack1 = __default(require("../helpers/upload_url.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"event_value") : depth0),{"name":"upload_url","hash":{},"data":data,"loc":{"start":{"line":9,"column":22},"end":{"line":9,"column":50}}})) != null ? stack1 : "")
    + "\"\n             loading=\"lazy\"\n             title=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"event_value") : depth0), depth0))
    + "\"\n             data-toggle=\"tooltip\"\n             width=\"100\"\n             height=\"100\"\n             style=\"height:100px; width:100px; object-fit:cover\"></span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <span class=\""
    + alias2(__default(require("../helpers/checkForPreBackground.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"event_name") : depth0),(depth0 != null ? lookupProperty(depth0,"event_value") : depth0),{"name":"checkForPreBackground","hash":{},"data":data,"loc":{"start":{"line":18,"column":19},"end":{"line":18,"column":68}}}))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":68},"end":{"line":18,"column":103}}})) != null ? stack1 : "")
    + "\">"
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"event_value") : depth0), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":120},"end":{"line":18,"column":151}}})) != null ? stack1 : "")
    + "</span>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return " [ADMIN]";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../helpers/join.js")).call(alias1,"; ",__default(require("../helpers/array.js")).call(alias1,__default(require("../helpers/capitalize.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"device") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":24,"column":140},"end":{"line":24,"column":164}}}),((stack1 = (depth0 != null ? lookupProperty(depth0,"device") : depth0)) != null ? lookupProperty(stack1,"model") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"browser") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),{"name":"array","hash":{},"data":data,"loc":{"start":{"line":24,"column":133},"end":{"line":24,"column":191}}}),{"name":"join","hash":{},"data":data,"loc":{"start":{"line":24,"column":121},"end":{"line":24,"column":193}}}));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias2(__default(require("../helpers/log.js")).call(alias1,"~~~~~~~ rendering analytics-history-grid",depth0,{"name":"log","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":55}}}))
    + "\n\n<pre class=\""
    + alias2(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"styles"))) && lookupProperty(stack1,"pre5")), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"response") : depth0)) != null ? lookupProperty(stack1,"analytics") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":26,"column":9}}})) != null ? stack1 : "")
    + "</pre>\n";
},"useData":true});